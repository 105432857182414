import "./styles.css";

import { NotActiveProps } from "./types";
import React from "react";
import logo from "../../assets/uda-logo.png";

const NotActive: React.FC<NotActiveProps> = () => {
  return (
    <div className="container-nf">
      <img src={logo} alt="UDA_logo" height="100px" width="100px" />
      <p className="description-nf">
        Le lezioni si svolgono in presenza. Maggiori informazioni sulla{" "}
	<a href="https://teledidattica.unich.it/">pagina principale</a> del sito
	di teledidattica.
      </p>
    </div>
  );
};

export default NotActive;
