import { Student } from "./../components/studentsList/types";
import { useMutation, queryCache } from "react-query";
import authInstance from "../API/AuthInstance";

export default function useDeleteAdmin() {
  return useMutation<Student, string>(
    async (id: string) => {
      const { data } = await authInstance.delete(`/user/${id}`);
      return data;
    },
    {
      onSettled: () => {
        queryCache.removeQueries("getAdmin");
      },
    }
  );
}
