import InnerMenu from "../../components/innerMenu/InnerMenu";
import Loader from "../../components/loader/Loader";
import { MenuItem } from "../../components/innerMenu/types";
import React from "react";
import { Response } from "../../components/menuPane/types";
import { dashify } from "../../utils";
import { useRouteMatch } from "react-router-dom";
import useTeamsChannel from "../../hooks/useTeamsChannel";

const Specialization: React.FunctionComponent = () => {
  const { data, isSuccess, isLoading } = useTeamsChannel("specializzazione");

  const match = useRouteMatch();

  const specializations: MenuItem[] = data?.map((specialization: Response) => {
    const teamsLinks: MenuItem[] = specialization.teamsLinks.map((link) => ({
      id: link.name,
      label: link.name.toUpperCase(),
      link: link.teamsLink,
    }));

    return {
      id: specialization.groupName,
      label: specialization.groupName.toUpperCase(),
      link: `${match.path}/${dashify(specialization.groupName)}`,
      subMenuItems: teamsLinks,
    };
  });

  return (
    <>
      <h3> Scuole di specializzazione</h3>
      <p>
        I team delle scuole di specializzazione per l'anno 2019/2020 sono pubblicati alla pagina{" "}
        <a href="https://zeus.unich.it/teledidattica19/#spec">https://zeus.unich.it/teledidattica19/#spec</a>.
      </p>
      <p>
        Per informazioni sui team delle scuole di specializzazione
        contattare la prof.ssa{" "}
        <a href="mailto:francesca.scozzari@unich.it">Francesca Scozzari</a>.
      </p>
      <h4>Lezioni Scuole di Specializzazione</h4>
      <ul>
         <li><a href="https://teams.microsoft.com/l/team/19:6b9e367e23f9455fb96f9ce7b5bb785b%40thread.tacv2/conversations?groupId=32ff27ce-16b5-46f5-9fc6-81b53d506d27&tenantId=41f8b7d0-9a21-415c-9c69-a67984f3d0de">[SS04 Lezioni] ONCOLOGIA MEDICA</a>
	 </li>
      </ul>	 
      <h4>Riunioni organi Scuole di Specializzazione</h4>
      <ul>
      	 <li><a href="https://teams.microsoft.com/l/team/19:bdfbab1807f64f9291375076bdd2f8fc%40thread.tacv2/conversations?groupId=bfe5fa3b-00f2-49ad-9696-7c5624cf744d&tenantId=41f8b7d0-9a21-415c-9c69-a67984f3d0de">[SS04] ONCOLOGIA MEDICA</a>
         </li>
      </ul>
      {isLoading ? (
        <Loader />
      ) : (
        isSuccess && <InnerMenu menuItems={specializations} hasChildren />
      )}
    </>
  );
};

export default Specialization;
