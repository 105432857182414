import { LinkInterface } from "./types";

export const didacticsLinks: LinkInterface[] = [
  {
    title: "Home",
    to: "/",
    exact: true,
    requiredFeatures: ["guest", "authenticated"],
  },
  {
    title: "Gestione Lezioni ed Esami",
    to: "/dashboard/lessons-exams",
    requiredFeatures: ["authenticated"],
  },
  {
    title: "Gestione Docenti",
    to: "/dashboard/teacher",
    requiredFeatures: ["authenticated"],
  },
  {
    title: "Gestione Contesti",
    to: "/dashboard/context",
    requiredFeatures: ["authenticated"],
  },
  {
    title: "Gestione Utenti",
    to: "/dashboard/admin",
    requiredFeatures: ["authenticated"],
  },
  {
    title: "Gestione Pre-registrazioni",
    to: "/dashboard/student",
    requiredFeatures: ["authenticated"],
  },
  {
    title: "Lezioni",
    to: "/lezioni-online",
    requiredFeatures: ["guest", "authenticated"],
  },
  {
    title: "Esami",
    to: "/esami",
    requiredFeatures: ["guest", "authenticated"],
  },
  {
    title: "Anni precedenti",
    to: "/teledidattica19",
    requiredFeatures: ["guest", "authenticated"],
  },
  {
    title: "Account teledidattica",
    to: "/account",
    requiredFeatures: ["guest", "authenticated"],
  },
  {
    title: "App prenotazione",
    to: "/app-prenotazione",
    requiredFeatures: ["guest", "authenticated"],
  },
  {
    title: "Docenti",
    to: "/docenti",
    requiredFeatures: ["guest", "authenticated"],
  },
  {
    title: "Specializzazione",
    to: "/specializzazione",
    requiredFeatures: ["guest", "authenticated"],
  },
  {
    title: "Master",
    to: "/master",
    requiredFeatures: ["guest", "authenticated"],
  },
  {
    title: "CLA",
    to: "/cla",
    requiredFeatures: ["guest", "authenticated"],
  },
  {
    title: "Esami di laurea",
    to: "/esami-laurea",
    requiredFeatures: ["guest", "authenticated"],
  },
  {
    title: "Organi collegiali",
    to: "/organi-collegiali",
    requiredFeatures: ["guest", "authenticated"],
  },
  {
    title: "Placement",
    to: "/placement",
    requiredFeatures: ["guest", "authenticated"],
  },
  {
    title: "Altre iniziative",
    to: "/altre-iniziative",
    requiredFeatures: ["guest", "authenticated"],
  },
  {
    title: "Linee guida esami",
    to: "/linee-guida-esami",
    requiredFeatures: ["guest", "authenticated"],
  },
  {
    title: "Tutorial",
    to: "/tutorial",
    requiredFeatures: ["guest", "authenticated"],
  },
  {
    title: "FAQ",
    to: "/faq",
    requiredFeatures: ["guest", "authenticated"],
  },
  {
    title: "Contatti",
    to: "/contatti",
    requiredFeatures: ["guest", "authenticated"],
  },
  {
    title: "Login",
    to: "/login",
    requiredFeatures: ["guest"],
  },
  {
    title: "Logout",
    to: "/logout",
    requiredFeatures: ["authenticated"],
  },
  /*   {
    title: "Studenti",
    to: "/studenti",
    requiredFeatures: ["authenticated"],
  }, */
  /*  {
    title: "Riunioni Collegiali",
    to: "/riunioni-collegiali",
    requiredFeatures: ["authenticated"],
  },
  {
    title: "Ricevimento Studenti",
    to: "/ricevimento-studenti",
    requiredFeatures: ["authenticated"],
  }, */
];
