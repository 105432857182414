import { Student } from "./../components/studentsList/types";
import axios from "axios";
import { useMutation, queryCache } from "react-query";

const { REACT_APP_API } = process.env;

export default function useUpdateFreshman() {
  return useMutation<Student, Student>(
    async (student: Student) => {
      const { data } = await axios.put(
        `${REACT_APP_API}/student/freshman/${student.code}`,
        student
      );
      return data;
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries("getUnsignedStudents");
      },
    }
  );
}
