import React, { useEffect } from "react";
import { TeacherFormProps, TeacherFormData } from "./types";
import { Form, Button } from "react-bootstrap";
import useCreateTeacher from "../../hooks/useCreateTeacher";
import useTeacherForm from "../../hooks/useTeacherForm";
import "./styles.css";
import Loader from "../loader/Loader";

const CreateTeacherForm: React.FunctionComponent<TeacherFormProps> = ({
  handleClose,
  renderToast,
  loading,
  setLoading,
}) => {
  const [mutate] = useCreateTeacher();
  const { register, handleSubmit, errors } = useTeacherForm({
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    studentID: "",
    isEnabled: true,
    teamsLink: "",
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  const onSubmit = (data: TeacherFormData) => {
    mutate(
      {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        studentID: data.studentID,
        isEnabled: true,
        username: data.studentID,
        teamsLink: data.teamsLink,
      },
      {
        onSuccess: () => {
          renderToast(true, "upd");
        },
        onError: () => {
          renderToast(false, "upd");
        },
      }
    );
    handleClose();
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId="formBasicName">
          <Form.Label>Nome</Form.Label>
          <Form.Control
            type="text"
            placeholder="Inserisci nome"
            name="firstName"
            ref={register}
          />
          {errors.firstName && (
            <p className="text-danger">{errors.firstName.message}</p>
          )}
        </Form.Group>
        <Form.Group controlId="formBasicLastname">
          <Form.Label>Cognome</Form.Label>
          <Form.Control
            type="text"
            placeholder="Inserisci cognome"
            name="lastName"
            ref={register}
          />
          {errors.lastName && (
            <p className="text-danger">{errors.lastName.message}</p>
          )}
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            placeholder="Inserisci email"
            name="email"
            ref={register}
          />
          {errors.email && (
            <p className="text-danger">{errors.email.message}</p>
          )}
        </Form.Group>
        <Form.Group controlId="formBasicStudentId">
          <Form.Label>Matricola</Form.Label>
          <Form.Control
            type="text"
            placeholder="Inserisci matricola"
            name="studentID"
            ref={register}
          />
        </Form.Group>
        <Form.Group controlId="formBasicStudentId">
          <Form.Label>Teams Link</Form.Label>
          <Form.Control
            type="text"
            placeholder="Inserisci link di Teams"
            name="teamsLink"
            ref={register}
          />
        </Form.Group>
        <div className="d-flex justify-content-end">
          <Button className="w-25 mr-2" type="submit">
            Crea
          </Button>
          <Button className="w-25" variant="secondary" onClick={handleClose}>
            Annulla
          </Button>
        </div>
      </Form>
    </>
  );
};

export default CreateTeacherForm;
