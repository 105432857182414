import React, { useEffect, useState } from "react";
import ContextTableProps, { TeamsLink } from "./types";
import { PencilIcon, TrashcanIcon } from "@primer/octicons-react";
import DeleteModal from "../deleteModal/index";
import UpdateModal from "../updateModal/index";
import useDeleteContext from "../../hooks/useDeleteContext";
import Loader from "../loader/Loader";

const ContextTable: React.FunctionComponent<ContextTableProps> = ({
  data,
  loading,
  setLoading,
  renderToast,
  choosenOpt,
}) => {
  const [confirmDelShow, setConfirmDelShow] = useState<boolean>(false);
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
  const [, setConfirmDel] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [selectedItem, setSelectedItem] = useState<TeamsLink>();
  const [mutateDel, { isLoading }] = useDeleteContext();

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  const checkLink = (link: string) => {
    if (link.includes("http://")) {
      return link;
    } else return `http://${link}`;
  };

  const handleDeleteContext = (id: string) => {
    setSelectedId(id);
    setConfirmDelShow(true);
  };

  const handleUpdateContext = (item: TeamsLink) => {
    setSelectedId(item.id);
    setSelectedItem(item);
    setShowUpdateModal(true);
  };

  const handleCloseDelModal = () => {
    setConfirmDelShow(false);
  };

  const handleCloseUpdModal = () => {
    setShowUpdateModal(false);
  };

  const confirmedDelete = () => {
    mutateDel(
      {
        id: selectedId,
        category: data.groupName,
      },
      {
        onSuccess: () => {
          renderToast(true, "del");
        },
        onError: () => {
          renderToast(false, "del");
        },
      }
    );
    setConfirmDelShow(false);
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Nome</th>
              <th scope="col">Collegamento</th>
              <th scope="col" className="d-flex justify-content-center">
                Azioni
              </th>
            </tr>
          </thead>
          <tbody>
            {data.teamsLinks?.map((teamlink: TeamsLink) => (
              <tr key={teamlink.name} style={{ cursor: "pointer" }}>
                <td>{teamlink.name}</td>
                <td>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={checkLink(teamlink.teamsLink)}
                    className="d-flex flex-row"
                  >
                    Teams link
                  </a>
                </td>
                <td className="d-flex justify-content-center">
                  <span
                    className="mr-5 justify-content-center"
                    onClick={() => handleUpdateContext(teamlink)}
                  >
                    <PencilIcon />
                  </span>
                  <span onClick={() => handleDeleteContext(teamlink.id)}>
                    <TrashcanIcon />
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <UpdateModal
          loading={loading}
          setLoading={setLoading}
          selectedId={selectedId}
          renderToast={renderToast}
          choosenOpt={choosenOpt}
          editItem={selectedItem}
          show={showUpdateModal}
          handleClose={handleCloseUpdModal}
        />
        <DeleteModal
          show={confirmDelShow}
          handleClose={handleCloseDelModal}
          confirm={setConfirmDel}
          confirmedDelete={confirmedDelete}
        />
      </div>
    </>
  );
};

export default ContextTable;
