import React, { useState } from "react";
import useAdmin from "../../hooks/useAdmin";
import Loader from "../loader/Loader";
import UserModal from "../userModal/UserModal";
import UserCreateModal from "../userCreateModal/UserCreateModal";
import { Admin, AdminsListProps } from "./types";
import { Button } from "react-bootstrap";

const AdminsList: React.FunctionComponent<AdminsListProps> = ({
  showModal,
  successModal,
  renderToast,
}) => {
  const { data, isSuccess, isLoading } = useAdmin();
  const [loading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [admin, setAdmin] = useState<Admin>();

  const handleCreate = () => {
    setShowCreate(true);
  };

  const handleShow = (currentadmin: Admin) => {
    setAdmin(currentadmin);
    setShow(true);
  };

  const handleCloseCreate = () => setShowCreate(false);

  const handleClose = () => setShow(false);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        isSuccess && (
          <>
            <div className="d-flex flex-row-reverse">
              <Button
                variant="primary"
                className="mb-4 d-flex flex-row-reverse"
                onClick={() => handleCreate()}
              >
                Aggiungi
              </Button>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Cognome</th>
                    <th scope="col">Email</th>
                    <th scope="col">Matricola</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((admin: Admin) => (
                    <tr
                      key={admin.studentID}
                      onClick={() => handleShow(admin)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{admin.firstName}</td>
                      <td>{admin.lastName}</td>
                      <td>{admin.email}</td>
                      <td>{admin.studentID}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )
      )}
      <UserCreateModal
        show={showCreate}
        handleClose={handleCloseCreate}
        showModal={showModal}
        successModal={successModal}
        renderToast={renderToast}
        loading={loading}
        setLoading={setLoading}
      />
      {admin && (
        <UserModal
          show={show}
          handleClose={handleClose}
          admin={admin}
          showModal={showModal}
          successModal={successModal}
          renderToast={renderToast}
          loading={loading}
          setLoading={setLoading}
        />
      )}
    </>
  );
};

export default AdminsList;
