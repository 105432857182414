import React, { useState } from "react";
import ContextListProps from "./types";
import useGetContext from "../../hooks/useGetContext";
import ContextTable from "../../components/contextTable/ContextTable";
import CreateContextModal from "../createContextModal/CreateContextModal";
import { Button } from "react-bootstrap";

const ContextForm: React.FunctionComponent<ContextListProps> = ({
  showModal,
  successModal,
  renderToast,
  choosenOpt,
}) => {
  const { data } = useGetContext(choosenOpt);
  const [loading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  const handleClose = () => setShow(false);

  return (
    <>
      <div className="d-flex flex-row-reverse">
        <Button
          variant="primary"
          className="mb-4 d-flex flex-row-reverse"
          onClick={() => setShow(true)}
        >
          Aggiungi
        </Button>
      </div>
      {data ? (
        <ContextTable
          choosenOpt={choosenOpt}
          data={data}
          showModal={showModal}
          successModal={successModal}
          renderToast={renderToast}
          loading={loading}
          setLoading={setLoading}
        />
      ) : (
        <h3>Elenco vuoto</h3>
      )}
      <CreateContextModal
        show={show}
        handleClose={handleClose}
        choosenOpt={choosenOpt}
        renderToast={renderToast}
        loading={loading}
        setLoading={setLoading}
      />
    </>
  );
};

export default ContextForm;
