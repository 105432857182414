import * as React from "react";

import { Container, Spinner } from "react-bootstrap";

const Loader: React.FunctionComponent = () => {
  return (
    <Container className="text-center mt-5">
      <Spinner animation="border" variant="info" />
    </Container>
  );
};

export default Loader;
