import CreateContextModalProps from "./types";
import CreateContextForm from "../createContextForm/index";
import { Modal } from "react-bootstrap";
import React from "react";

const CreateContextModal: React.FunctionComponent<CreateContextModalProps> = ({
  show,
  handleClose,
  loading,
  setLoading,
  choosenOpt,
  renderToast,
}) => {
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Crea Contesto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateContextForm
            loading={loading}
            setLoading={setLoading}
            renderToast={renderToast}
            choosenOpt={choosenOpt}
            handleClose={handleClose}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateContextModal;
