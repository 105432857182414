import "./styles.css";

import { NotFoundProps } from "./types";
import React from "react";
import logo from "../../assets/uda-logo.png";

const NotFound: React.FC<NotFoundProps> = () => {
  return (
    <div className="container-nf">
      <img src={logo} alt="UDA_logo" height="100px" width="100px" />
      <p className="title-nf"> 404 </p>
      <p className="subtitle-nf"> Oooopss!! </p>
      <p className="description-nf">
        THAT PAGE DOESN&apos;T EXIST OR IS UNAVAILABLE{" "}
      </p>
    </div>
  );
};

export default NotFound;
