import { StudentFormData } from "./../components/editStudentForm/types";
import { yupResolver } from "@hookform/resolvers";
import { useForm } from "react-hook-form";
/* const [mutate, result] = useUpdateFreshman() */
import * as yup from "yup";

const useStudentForm = (defaultValues: StudentFormData) => {
  const schema = yup.object().shape({
    firstname: yup.string().required("Campo obbligatorio"),
    lastname: yup.string().required("Campo obbligatorio"),
    email: yup.string().email("Il campo deve essere una email").required(),
  });

  return useForm<StudentFormData>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });
};

export default useStudentForm;
