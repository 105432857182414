import React from "react";
import ToastBarProps from "./type";
import { Toast } from "react-bootstrap";
import "./styles.css";

const ToastBar: React.FunctionComponent<ToastBarProps> = ({
  result,
  type,
  showModal,
  setShowModal,
}) => {
  return (
    <>
      {type === "upd" ? (
        <Toast
          show={showModal}
          className={result ? "toast-wrapper-success" : "toast-wrapper-error"}
          onClose={() => setShowModal(false)}
          delay={2000}
          autohide
        >
          <Toast.Body className="toast-body">
            {result
              ? "Azione effettuata con successo"
              : "ATTENZIONE! Azione non effettuata"}
          </Toast.Body>
        </Toast>
      ) : (
        <Toast
          show={showModal}
          className={result ? "toast-wrapper-success" : "toast-wrapper-error"}
          onClose={() => setShowModal(false)}
          delay={2000}
          autohide
        >
          <Toast.Body className="toast-body">
            {result
              ? "Elemento eliminato con successo"
              : "ATTENZIONE! Elemento non eliminato"}
          </Toast.Body>
        </Toast>
      )}
    </>
  );
};

export default ToastBar;
