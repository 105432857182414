import { Teacher } from "../components/teacherDashList/types";
import { TeacherFormData } from "../components/editTeacherForm/types";
import { useMutation, queryCache } from "react-query";
import authInstance from "../API/AuthInstance";

export default function useUpdateTeacher() {
  return useMutation<Teacher, TeacherFormData>(
    async (teacher: TeacherFormData) => {
      const { data } = await authInstance.put(
        `/teacher/${teacher.studentID}`,
        teacher
      );
      return data;
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries("getTeacher");
      },
    }
  );
}
