import "./styles.css";

import InnerMenu from "../innerMenu/InnerMenu";
import { MenuItem } from "../innerMenu/types";
import React from "react";
import SidebarProps from "./types";

const Sidebar: React.FunctionComponent<SidebarProps> = ({
  links,
  isLgViewport,
}) => {
  const menuItems: MenuItem[] = links.map((link) => ({
    id: link.title,
    label: link.title,
    link: link.to,
    exact: link.exact,
  }));

  return (
    <>
      {isLgViewport ? (
        <div className="sidebar">
          <InnerMenu menuItems={menuItems} />
        </div>
      ) : null}
    </>
  );
};

export default Sidebar;
