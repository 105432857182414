import React, { useState } from "react";
import ContextDashboardProps from "./types";
import ContextForm from "../../components/contextForm/ContextForm";
import ContextList from "../../components/contextList/ContextList";
import ToastBar from "../../components/ToastBar/index";

const ContextDashboard: React.FunctionComponent<ContextDashboardProps> = () => {
  const [choosenOpt, setChoosenOpt] = useState<string>("cla");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>("");

  const renderToast = (result: boolean, type: string) => {
    setShowModal(true);
    setSuccessModal(result);
    setModalType(type);
  };

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setChoosenOpt(e.target.value);
  };
  return (
    <>
      <h1 className="mb-4">SCEGLI IL CONTESTO</h1>
      <ToastBar
        result={successModal}
        setShowModal={setShowModal}
        showModal={showModal}
        type={modalType}
      />
      <ContextForm handleSelect={handleSelect} choosenOpt={choosenOpt} />
      <ContextList
        choosenOpt={choosenOpt}
        showModal={showModal}
        successModal={successModal}
        renderToast={renderToast}
      />
    </>
  );
};

export default ContextDashboard;
