import { Alert } from "react-bootstrap";
import BookingAppProps from "./types";
import React from "react";

const BookingApp: React.FunctionComponent<BookingAppProps> = () => {
  return (
    <>
{/*      <Alert variant="primary">
        <span className="text-danger font-weight-bold">AVVISO per gli studenti</span> Possono prenotarsi
	per frequentare le lezioni in presenza tutti gli studenti regolarmente
	iscritti per l'a.a. 2021/2022 (cioè che abbiano perfezionato l'iscrizione con il pagamento della prima rata)
	e gli studenti iscritti all'a.a. 2020/2021.
	Gli studenti pre-immatricolati o in corso di pre-valutazione (ossia agli studenti che - pur essendosi
	registrati sul Portale di Ateneo - non abbiano ancora perfezionato l’iscrizione con il pagamento della prima rata)
	potranno seguire le lezioni da remoto sulla piattaforma Teams di ateneo fino al termine di scadenza
	delle immatricolazioni/iscrizioni del 5 novembre 2021.
      </Alert>
*/}
     <Alert variant="primary">
        <span className="text-danger font-weight-bold">AVVISO per gli studenti</span>
	   <p>A partire dal 1 maggio 2022 è stata ripristina la normale capienza nelle aule al 100% e non è più necessario
	   l'utilizzo dell'app per la prenotazione del posto in aula.
	   </p>
	   <p>Pur non sussistendo un obbligo specifico di utilizzo dei dispositivi individuali di protezione delle vie
	   respiratorie (mascherine FFP2), se ne raccomanda l'uso all'interno delle aule ed in generale negli ambienti
	   chiusi in cui si trovino più persone.
	   </p>
      </Alert>
      <p>
      L'app per la prenotazione del posto è disponibile su{" "}
      <a href="https://play.google.com/store/apps/details?id=it.unich.udaprenotazioni">Google Play</a> e{" "} 
      <a href="https://apps.apple.com/it/app/uda-prenotazioni/id1533994989">Apple App Store</a>.
      </p>
      <ul>
	<li>
	  Per effettuare il login nell'app utilizzare le credenziali in proprio possesso per accedere a{" "}
          <a href="https://udaonline.unich.it/">udaonline.unich.it</a>.
	</li>
	<li>
	  L'app permette di prenotare un posto per frequentare in presenza le lezioni di uno o più
	  insegnamenti o moduli, sino alla capienza massima individuata in osservanza delle vigenti
	  disposizioni di sicurezza anticontagio. Le prenotazioni sono aperte nelle giornate:
	  <ul>
	   <li> giovedì dalle ore 07:00 <strong>solo</strong> per gli studenti con disabilità; </li>
           <li>	venerdì	dalle ore 07:00	per tutti gli studenti; </li>
           <li> sabato per tutti gli studenti. </li>
	  </ul>
         Sono prenotabili solamente le lezioni della settimana successiva.
	</li>
	<li>
	  L'app permette inoltre di disdire la prenotazione in caso di impossibilità di frequenza,
	  con lo scorrimento di eventuali liste d'attesa.
	</li>
      </ul>
      <p> Si raccomanda di aggiornare l'app all'ultima versione.
      </p>
      <p>
	Scarica il{" "}
          <a href="https://zeus.unich.it/teledidattica19/doc/AppUdAPrenota.pdf">manuale dell'app UdA Prenota</a>.
      </p>
    </>
  );
};

export default BookingApp;
