import { Alert } from "react-bootstrap";
import FormsProps from "./types";
import React from "react";

const Forms: React.FunctionComponent<FormsProps> = () => {
  return (
    <>
      <p> Le informazioni sulla modalità di erogazione della didattica per l'a.a. 2023/2024
        sono disponibili in questa{" "}
        <a href="https://www.unich.it/avvisi/disposizioni-sulle-modalita-di-erogazione-delle-attivita-didattiche-aa-20232024">pagina</a>.
      </p>  
      <p>
        Gli studenti e le studentesse che hanno aderito all'iniziativa "PA 110 e lode" per la formazione della pubblica amministrazione
	{" "}<strong>non</strong>{" "} devono effettuare nessuna domanda
	in quanto sono automaticamente inseriti nei teams della didattica a distanza. 
      </p>
      <p>Gli studenti e le studentesse con invalidità o disabilità che intendono avvalersi della didattica a distanza
      possono farne richiesta seguendo le istruzioni ricevute sulla posta elettronica di Ateneo.
      </p>
    </>
  );
};

export default Forms;
