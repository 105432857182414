import { Alert, Row } from "react-bootstrap";
import React, { useState } from "react";

import InnerMenu from "../innerMenu/InnerMenu";
import { MenuPaneProps } from "./types";
import TeamsLinks from "../teamsLinks/TeamsLinks";
import { useRouteMatch } from "react-router-dom";

const MenuPane: React.FunctionComponent<MenuPaneProps> = ({ menuItems, filter }) => {
  const match = useRouteMatch();
  const [submenuItemCode, setSubmenuItemCode] = useState<string>("");
  const [currentCourseOfStudyCode, setCurrentCourseOfStudyCode] = useState<
    string
  >("");

  const string =
    match.path === "/lezioni-online"
      ? "le lezioni"
      : match.path === "/esami" && "gli esami";

  return (
    <Row>
      <InnerMenu
        menuItems={menuItems}
        setSubmenuItemCode={setSubmenuItemCode}
        submenuItemCode={submenuItemCode}
        setCurrentCourseOfStudyCode={setCurrentCourseOfStudyCode}
        currentCourseOfStudyCode={currentCourseOfStudyCode}
      >
        {menuItems.map(
          (item) =>
            currentCourseOfStudyCode === item.id &&
            (item.subMenuItems && item.subMenuItems.length !== 1 ? (
              <div key={item.id}>
                {submenuItemCode === "" && (
                  <Alert variant="primary">
                    Seleziona un percorso di studio per vedere {string}
                  </Alert>
                )}
                {item.subMenuItems?.map((submenuItem) => (
                  <>
                    {submenuItemCode === submenuItem.id &&
                      submenuItem.teamsLinks?.length === 0 && (
                        <Alert variant="danger">
                          Per questo percorso di studio non sono presenti{" "}
                          {string}
                        </Alert>
                      )}
                    {submenuItemCode === submenuItem.id && (
                      <TeamsLinks key={submenuItem.id} menuItem={submenuItem} filter={filter} />
                    )}
                  </>
                ))}
              </div>
            ) : item.teamsLinks?.length === 0 ? (
              <Alert variant="danger">
                Per questo corso di studio non sono presenti {string}
              </Alert>
            ) : (
              <TeamsLinks key={item.id} menuItem={item} filter={filter} />
            ))
        )}
      </InnerMenu>
    </Row>
  );
};

export default MenuPane;
