import React, { useEffect, useState } from "react";
import AuthenticationContext from "./context";
import authInstance from "../../API/AuthInstance";

const AuthenticationProvider: React.FC = ({ children }) => {
  const [token, setToken] = useState<string | null>(
    localStorage.getItem("token")
  );

  useEffect(() => {
    const interceptorRequestId = authInstance.interceptors.request.use(
      (request) => {
        request.headers.Authorization = `Bearer ${localStorage.getItem(
          "token"
        )}`;
        return request;
      }
    );
    const interceptorResponseId = authInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          onSetAccessToken("");
        }
        return Promise.reject(error);
      }
    );
    return () => {
      authInstance.interceptors.response.eject(interceptorResponseId);
      authInstance.interceptors.request.eject(interceptorRequestId);
    };
  }, []);

  const onSetAccessToken = (token: string) => {
    setToken(token);
    localStorage.setItem("token", token);
  };
  return (
    <AuthenticationContext.Provider
      value={{ accessToken: token, setAccessToken: onSetAccessToken }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationProvider;
