import "./styles.css";

import { InnerMenuProps, MenuItemType } from "./types";
import React, { useCallback, useState } from "react";

import { Container } from "react-bootstrap";
import InnerMenuItem from "./InnerMenuItem";
import InnerSubMenu from "./InnerSubMenu";

const InnerMenu: React.FC<InnerMenuProps> = ({
  menuItems,
  hasChildren,
  children,
  setSubmenuItemCode,
  submenuItemCode,
  setCurrentCourseOfStudyCode,
  currentCourseOfStudyCode,
}) => {
  const [currentMenuItemCode, setCurrentMenuItemCode] = useState<string>("");
  const handleExpandMenu = useCallback(
    (item: MenuItemType) => () => {
      setSubmenuItemCode && setSubmenuItemCode("");
      setCurrentMenuItemCode((prevId) => (item.id !== prevId ? item.id : ""));

      return (
        setCurrentCourseOfStudyCode &&
        setCurrentCourseOfStudyCode((prevId) =>
          item.id !== prevId ? item.id : ""
        )
      );
    },
    [setCurrentCourseOfStudyCode, setSubmenuItemCode]
  );

  return (
    <Container>
      {menuItems.map((item: MenuItemType) => {
        return (
          <div key={item.label} className="mb-3">
            <InnerMenuItem
              setCurrentItemCode={setSubmenuItemCode}
              exactLink={item.exact}
              menuItem={item}
              handleExpandMenu={handleExpandMenu(item)}
              currentMenuItem={currentCourseOfStudyCode ?? ""}
              hasChildren={hasChildren}
              setCurrentCourseOfStudyCode={setCurrentCourseOfStudyCode}
            />
            {(currentCourseOfStudyCode === item.id &&
              item.subMenuItems &&
              item.subMenuItems.length > 1) ||
            ((currentCourseOfStudyCode === item.id ||
              currentMenuItemCode === item.id) &&
              hasChildren) ? (
              <>
                {submenuItemCode === "" && children}
                <InnerSubMenu
                  subMenuItems={item.subMenuItems}
                  setCurrentPathOfStudyCode={setSubmenuItemCode}
                />
                {submenuItemCode !== "" && children}
              </>
            ) : currentCourseOfStudyCode === item.id ? (
              children
            ) : null}
          </div>
        );
      })}
    </Container>
  );
};

export default InnerMenu;
