import React, { useEffect } from "react";
import { LessonCreateFinalFormProps, Lesson } from "./types";
import { Form, Button } from "react-bootstrap";
import useCreateLessonFinal from "../../hooks/useCreateLessonFinal";
import useLessonFinalForm from "../../hooks/useLessonFinalForm";
import "./styles.css";

const LessonCreateFinalForm: React.FunctionComponent<LessonCreateFinalFormProps> = ({
  handleClose,
  renderToast,
  setLoading,
  pos,
  cos,
}) => {
  const [mutate, { isLoading }] = useCreateLessonFinal();
  const { register, handleSubmit, errors } = useLessonFinalForm({
    code: "",
    courseOfStudyCode: cos,
    pathOfStudyId: pos,
    description: "",
    teamsLink: "",
  });

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  const onSubmit = (data: Lesson) => {
    mutate(
      {
        code: data.code,
        courseOfStudyCode: data.courseOfStudyCode,
        pathOfStudyId: data.pathOfStudyId,
        description: data.description,
        teamsLink: data.teamsLink,
      },
      {
        onSuccess: () => {
          renderToast(true, "upd");
          setLoading(false);
          handleClose();
        },
        onError: () => {
          renderToast(false, "upd");
          setLoading(false);
          handleClose();
        },
      }
    );
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId="formBasicStudentId">
          <Form.Label>Codice corso</Form.Label>
          <Form.Control
            readOnly={true}
            type="text"
            placeholder="Inserisci il codice del corso"
            name="courseOfStudyCode"
            ref={register}
          />
          {errors.courseOfStudyCode && (
            <p className="text-danger">{errors.courseOfStudyCode.message}</p>
          )}
          <Form.Label>Codice Percorso</Form.Label>
          <Form.Control
            readOnly={true}
            type="text"
            placeholder="Inserisci il codice del percorso"
            name="pathOfStudyId"
            ref={register}
          />
          {errors.pathOfStudyId && (
            <p className="text-danger">{errors.pathOfStudyId.message}</p>
          )}
          <Form.Label>Codice Lezione</Form.Label>
          <Form.Control
            type="text"
            placeholder="Inserisci il codice della lezione"
            name="code"
            ref={register}
          />
          {errors.code && <p className="text-danger">{errors.code.message}</p>}
          <Form.Label>Descrizione</Form.Label>
          <Form.Control
            type="text"
            placeholder="Inserisci la descrizione"
            name="description"
            ref={register}
          />
          {errors.description && (
            <p className="text-danger">{errors.description.message}</p>
          )}
          <Form.Label>Link di Teams</Form.Label>
          <Form.Control
            type="text"
            placeholder="Inserisci il link a teams"
            name="teamsLink"
            ref={register}
          />
          {errors.teamsLink && (
            <p className="text-danger">{errors.teamsLink.message}</p>
          )}
        </Form.Group>
        <div className="d-flex justify-content-end">
          <Button className="w-25 mr-2" type="submit">
            Crea
          </Button>
          <Button className="w-25" variant="secondary" onClick={handleClose}>
            Annulla
          </Button>
        </div>
      </Form>
    </>
  );
};

export default LessonCreateFinalForm;
