import React, { useCallback } from "react";

import { InnerMenuItemProps } from "./types";
import { NavLink } from "react-router-dom";
import { Row } from "react-bootstrap";
import arrow from "../../assets/expand-arrow.png";

const InnerMenuItem: React.FunctionComponent<InnerMenuItemProps> = ({
  hasChildren,
  exactLink,
  menuItem,
  currentMenuItem,
  handleExpandMenu,
  setCurrentItemCode,
  setCurrentCourseOfStudyCode,
}) => {
  const isItemExpandable = hasChildren ?? menuItem.subMenuItems;

  const handleClick = useCallback(
    (id) => () => {
      setCurrentCourseOfStudyCode && setCurrentCourseOfStudyCode(id);
      return setCurrentItemCode ? setCurrentItemCode(id) : undefined;
    },
    [setCurrentCourseOfStudyCode, setCurrentItemCode]
  );

  return (
    <div className="menu-item-container">
      <NavLink
        to={menuItem.link}
        exact={exactLink}
        onClick={
          handleExpandMenu && isItemExpandable
            ? () => handleExpandMenu(menuItem)
            : handleClick(menuItem.id)
        }
        className="menu-item mb-3"
      >
        <Row className="w-100 justify-content-between">
          <div className={isItemExpandable ? "w-75" : "w-100"}>
            {menuItem.label}
          </div>
          {isItemExpandable && (
            <div className="w-30">
              <img
                src={arrow}
                alt="arrow"
                width={15}
                height={15}
                className={
                  currentMenuItem === menuItem.id ? "expanded" : "collapsed"
                }
              />
            </div>
          )}
        </Row>
      </NavLink>
    </div>
  );
};

export default InnerMenuItem;
