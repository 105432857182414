import { DeleteModalProps } from "./types";
import { Button, Modal } from "react-bootstrap";
import React from "react";
import "./styles.css";

const DeleteModal: React.FunctionComponent<DeleteModalProps> = ({
  show,
  handleClose,
  confirmedDelete,
}) => {
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <span> Confermi di voler eliminare?</span>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <Button
              variant="primary"
              onClick={confirmedDelete}
              className="w-100 mr-1"
            >
              CONFERMA
            </Button>
            <Button variant="secondary" onClick={handleClose} className="w-100">
              ANNULLA
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteModal;
