import { useQuery } from "react-query";
import authInstance from "../API/AuthInstance";

const getDashboardLesson = async (text: string, id: string, idpath: string) => {
  const { data } = await authInstance.get(`/lesson/${id}/${idpath}`);
  return data;
};

export default function useDashboarLesson(id: string, idpath: string) {
  return useQuery(["getLessonExam", id, idpath], getDashboardLesson);
}
