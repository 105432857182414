import { Teacher } from "./../components/teacherDashList/types";
import { TeacherFormData } from "../components/createTeacherForm/types";
import { useMutation, queryCache } from "react-query";
import authInstance from "../API/AuthInstance";

export default function useCreateTeacher() {
  return useMutation<Teacher, TeacherFormData>(
    async (teacher: TeacherFormData) => {
      const { data } = await authInstance.post(`/teacher`, teacher);
      return data;
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries("getTeacher");
      },
    }
  );
}
