import React, { useState } from "react";
import useTeacher from "../../hooks/useTeacher";
import Loader from "../loader/Loader";
import TeacherModal from "../teacherModal/TeacherModal";
import TeacherCreateModal from "../teacherCreateModal/TeacherCreateModal";
import { Teacher, TeacherListProps } from "./types";
import { Button } from "react-bootstrap";

const TeacherDashList: React.FunctionComponent<TeacherListProps> = ({
  showModal,
  successModal,
  renderToast,
}) => {
  const { data, isSuccess, isLoading } = useTeacher();
  const [loading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [teacher, setTeacher] = useState<Teacher>();

  const handleCreate = () => {
    setShowCreate(true);
  };

  const handleShow = (currenteacher: Teacher) => {
    setTeacher(currenteacher);
    setShow(true);
  };

  const handleCloseCreate = () => setShowCreate(false);

  const handleClose = () => setShow(false);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        isSuccess && (
          <>
            <div className="d-flex flex-row-reverse">
              <Button
                variant="primary"
                className="mb-4 d-flex flex-row-reverse"
                onClick={() => handleCreate()}
              >
                Aggiungi
              </Button>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Cognome</th>
                    <th scope="col">Email</th>
                    <th scope="col">Matricola</th>
                    <th scope="col">Teams Link</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((teacher: Teacher) => (
                    <tr
                      key={teacher.studentID}
                      onClick={() => handleShow(teacher)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{teacher.firstName}</td>
                      <td>{teacher.lastName}</td>
                      <td>{teacher.email}</td>
                      <td>{teacher.studentID}</td>
                      <td>{teacher.teamsLink}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )
      )}
      <TeacherCreateModal
        show={showCreate}
        handleClose={handleCloseCreate}
        showModal={showModal}
        successModal={successModal}
        renderToast={renderToast}
        loading={loading}
        setLoading={setLoading}
      />
      {teacher && (
        <TeacherModal
          show={show}
          handleClose={handleClose}
          teacher={teacher}
          showModal={showModal}
          successModal={successModal}
          renderToast={renderToast}
          loading={loading}
          setLoading={setLoading}
        />
      )}
    </>
  );
};

export default TeacherDashList;
