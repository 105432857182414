import React, { useEffect } from "react";
import { CreateContextProps, ContextFormData } from "./types";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import useContextForm from "../../hooks/useContextForm";
import useCreateContext from "../../hooks/useCreateContext";
import Loader from "../loader/Loader";
import { useFieldArray } from "react-hook-form";

const CreateContextForm: React.FunctionComponent<CreateContextProps> = ({
  choosenOpt,
  handleClose,
  renderToast,
  loading,
  setLoading,
}) => {
  const [mutate, { isLoading }] = useCreateContext();
  const { register, handleSubmit, control, errors } = useContextForm({
    groupName: "",
    category: "",
    name: "",
    teamsLink: "",
    ownerEmail: "",
    invitations: [],
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "invitations",
  });

  useEffect(() => {
    setLoading(isLoading);
  }, [setLoading, isLoading]);

  const onSubmit = (data: ContextFormData) => {
    mutate(
      {
        groupName: choosenOpt,
        category: choosenOpt,
        name: data.name,
        ownerEmail: data.ownerEmail,
        invitations: data.invitations.map((inv: any) => inv.email),
      },
      {
        onSuccess: () => {
          renderToast(true, "upd");
          setLoading(false);
        },
        onError: () => {
          renderToast(false, "upd");
          setLoading(false);
        },
      }
    );
    handleClose();
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId="formBasicName">
          <Form.Label>Nome</Form.Label>
          <Form.Control
            type="text"
            placeholder="Inserisci nome"
            name="name"
            ref={register}
          />
          {errors.name && <p className="text-danger">{errors.name.message}</p>}
        </Form.Group>
        <Form.Group controlId="formBasicLastname">
          <Form.Label>Amministratore Teams</Form.Label>
          <Form.Control
            type="email"
            placeholder="Inserisci email amministratore"
            name="ownerEmail"
            ref={register}
          />
          {errors.ownerEmail && (
            <p className="text-danger">{errors.ownerEmail.message}</p>
          )}
        </Form.Group>
        <Form.Group controlId="formBasicLastname">
          <Form.Label>Invitati Teams</Form.Label>
          <Container>
            {fields.map((item, index) => (
              <Row key={item.id} className="mb-1">
                <Col sm={8}>
                  <Form.Control
                    type="email"
                    placeholder="Inserisci email invitati"
                    name={`invitations[${index}].email`}
                    ref={register()}
                    defaultValue={item.email}
                  />
                </Col>
                <Col sm={4}>
                  <Button
                    type="button"
                    onClick={() => remove(index)}
                    variant="danger"
                  >
                    Cancella
                  </Button>
                </Col>
              </Row>
            ))}
          </Container>
          <Button
            type="button"
            onClick={() => append({ email: "" })}
            className="mt-2"
          >
            Aggiungi
          </Button>
        </Form.Group>
        <div className="d-flex justify-content-end">
          <Button className="w-25 mr-2" type="submit">
            Conferma
          </Button>
          <Button className="w-25" variant="secondary" onClick={handleClose}>
            Annulla
          </Button>
        </div>
      </Form>
    </>
  );
};

export default CreateContextForm;
