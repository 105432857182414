import BookingApp from "../screens/bookingApp";
import Forms from "../screens/forms";
import Account from "../screens/account";
import Cla from "../screens/cla";
import CollegialBodies from "../screens/collegialBodies";
import Contacts from "../screens/contacts";
import DegreeExams from "../screens/degreeExams";
import Exams from "../screens/exams";
import Exams2020 from "../screens/exams2020";
import ExamsGuidelines from "../screens/examsGuidelines";
import InfoRespondus from "../screens/infoRespondus";
import Faq from "../screens/faq";
import Home from "../screens/home";
import Master from "../screens/master";
import NotFound from "../screens/notFound";
import NotActive from "../screens/notActive";
import OnlineLessons from "../screens/onlineLessons";
import OnlineLessons2020 from "../screens/onlineLessons2020";
import Placement from "../screens/placement";
import AltreIniziative from "../screens/altreIniziative";
import { RoutePropsExtended } from "./types";
import Specialization from "../screens/specialization";
import Teachers from "../screens/teachers";
import Tutorial from "../screens/tutorial";
import Login from "../screens/login";
import Logout from "../screens/logout";
import StudentDashboard from "../screens/studentDashboard";
import TeacherDashboard from "../screens/teacherDashboard";
import ContextDashboard from "../screens/contextDashboard";
import LessonExamDashboard from "../screens/lessonExamDashboard";
import FinaleLessonList from "../components/finalLessonlist/index";
import AdminDashboard from "../screens/dashboard";

export const routes: RoutePropsExtended[] = [
  {
    key: "",
    requiredFeatures: ["guest", "authenticated"],
    path: "/",
    exact: true,
    component: Home,
  },
  {
    key: "Login",
    requiredFeatures: ["guest"],
    path: "/login",
    component: Login,
  },
 {
    key: "Online Lessons",
    requiredFeatures: ["guest", "authenticated"],
    path: "/lezioni-online",
    component: OnlineLessons,
  },
  {
    key: "Online Lessons 2020/2021",
    requiredFeatures: ["guest", "authenticated"],
    path: "/lezioni-online-2020",
    component: OnlineLessons2020,
  },
  {
    key: "Booking App",
    requiredFeatures: ["guest", "authenticated"],
    path: "/app-prenotazione",
    component: BookingApp,
  },
  {
    key: "Forms",
    requiredFeatures: ["guest", "authenticated"],
    path: "/forms",
    component: Forms,
  },  
  {
    key: "Account",
    requiredFeatures: ["guest", "authenticated"],
    path: "/account",
    component: Account,
  },
  {
    key: "Exams Guidelines",
    requiredFeatures: ["guest", "authenticated"],
    path: "/linee-guida-esami",
    component: ExamsGuidelines,
  },
  {
    key: "Info Respondus",
    requiredFeatures: ["guest", "authenticated"],
    path: "/info-respondus",
    component: InfoRespondus,
  },
  {
    key: "Exams",
    requiredFeatures: ["guest", "authenticated"],
    path: "/esami",
    component: Exams,
  },
  {
    key: "Exams2020",
    requiredFeatures: ["guest", "authenticated"],
    path: "/esami-2020",
    component: Exams2020,
  },
  {
    key: "Teachers",
    requiredFeatures: ["guest", "authenticated"],
    path: "/docenti",
    component: Teachers,
  },
  {
    key: "Specialization",
    requiredFeatures: ["guest", "authenticated"],
    path: "/specializzazione",
    component: Specialization,
  },
  {
    key: "Master",
    requiredFeatures: ["guest", "authenticated"],
    path: "/master",
    component: Master,
  },
  {
    key: "Cla",
    requiredFeatures: ["guest", "authenticated"],
    path: "/cla",
    component: Cla,
  },
  {
    key: "Degree Exams",
    requiredFeatures: ["guest", "authenticated"],
    path: "/esami-laurea",
    component: DegreeExams,
  },
  {
    key: "Collegial Bodies",
    requiredFeatures: ["guest", "authenticated"],
    path: "/organi-collegiali",
    component: CollegialBodies,
  },
  {
    key: "Placement",
    requiredFeatures: ["guest", "authenticated"],
    path: "/placement",
    component: Placement,
  },
  {
    key: "Altre iniziative",
    requiredFeatures: ["guest", "authenticated"],
    path: "/altre-iniziative",
    component: AltreIniziative,
  },
  {
    key: "Tutorial",
    requiredFeatures: ["guest", "authenticated"],
    path: "/tutorial",
    component: Tutorial,
  },
  {
    key: "Faq",
    requiredFeatures: ["guest", "authenticated"],
    path: "/faq",
    component: Faq,
  },
  {
    key: "Contacts",
    requiredFeatures: ["guest", "authenticated"],
    path: "/contatti",
    component: Contacts,
  },
  {
    key: "Logout",
    requiredFeatures: ["authenticated"],
    path: "/logout",
    component: Logout,
  },
  {
    key: "Dashboard Context",
    requiredFeatures: ["authenticated"],
    path: "/dashboard/context",
    component: ContextDashboard,
  },
  {
    key: "Dashboard Students",
    requiredFeatures: ["authenticated"],
    path: "/dashboard/student",
    component: StudentDashboard,
  },
  {
    key: "Dashboard Teachers",
    requiredFeatures: ["authenticated"],
    path: "/dashboard/teacher",
    component: TeacherDashboard,
  },
  {
    key: "Dashboard Lessons and Exams",
    requiredFeatures: ["authenticated"],
    path: "/dashboard/lessons-exams/:id/:idp",
    component: FinaleLessonList,
  },
  {
    key: "Dashboard Lessons and Exams",
    requiredFeatures: ["authenticated"],
    path: "/dashboard/lessons-exams",
    component: LessonExamDashboard,
  },
  {
    key: "Dashboard",
    requiredFeatures: ["authenticated"],
    path: "/dashboard/admin",
    component: AdminDashboard,
  },

  /*   {
    key: "Students",
    requiredFeatures: ["authenticated"],
    path: "/studenti",
    component: Contacts,
  }, */
  /*   {
    key: "Collegial Meetings",
    requiredFeatures: ["authenticated"],
    path: "/riunioni-collegiali",
    component: Contacts,
  }, */
  /*   {
    key: "Student Reception",
    requiredFeatures: ["authenticated"],
    path: "/ricevimento-studenti",
    component: Contacts,
  }, */
  {
    key: "404",
    requiredFeatures: ["guest", "authenticated"],
    path: "/not-found",
    component: NotFound,
  },
  {
    key: "notactive",
    requiredFeatures: ["guest", "authenticated"],
    path: "/not-active",
    component: NotActive,
  },
];
