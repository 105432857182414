import { Channel } from "../interfaces/channelTypes";
import axios from "axios";
import { useQuery } from "react-query";

const { REACT_APP_API } = process.env;

const getTeamsChannelBySlug = async (_: any, slug: Channel) => {
  const { data } = await axios.get(`${REACT_APP_API}/team-channel/${slug}`);

  return data;
};

export default function useTeamsChannel(slug: Channel) {
  return useQuery(["TeamsChannelSlug", slug], getTeamsChannelBySlug);
}
