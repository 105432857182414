import { ContextFormData } from "./../components/createContextForm/types";
import { yupResolver } from "@hookform/resolvers";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const useContextForm = (defaultValues: ContextFormData) => {
  const schema = yup.object().shape({
    name: yup.string().required("Campo obbligatorio"),
    ownerEmail: yup
      .string()
      .email("Il campo deve essere una email")
      .required("Campo obbligatorio"),
    invitations: yup.array(),
  });

  return useForm<ContextFormData>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });
};

export default useContextForm;
