import React, { useMemo } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { DidactictsRoutesProps } from "./types";

const DidactictsRoutes: React.FC<DidactictsRoutesProps> = ({
  routes,
  children,
}) => {
  const Routes = useMemo(() => {
    return routes.map(({ key, ...route }) => <Route key={key} {...route} />);
  }, [routes]);

  return (
    <div className="mx-5 ml-xl-0">
      {routes ? (
        <Switch>
          {Routes}
          <Route
            key="Teledidattica19"
            path="/teledidattica19"
            requiredFeatures={["guest", "authenticated"]}
            component={() => {
              window.open("https://zeus.unich.it/teledidattica19");
              return null;
            }}
          />
          <Redirect to="/not-found" />
        </Switch>
      ) : (
        children
      )}
    </div>
  );
};

export default DidactictsRoutes;
