import ClaProps from "./types";
import InnerMenu from "../../components/innerMenu/InnerMenu";
import Loader from "../../components/loader/Loader";
import { MenuItem } from "../../components/innerMenu/types";
import React from "react";
import { Response } from "../../components/menuPane/types";
import { dashify } from "../../utils";
import { useRouteMatch } from "react-router-dom";
import useTeamsChannel from "../../hooks/useTeamsChannel";

const Cla: React.FunctionComponent<ClaProps> = () => {
  const { data, isSuccess, isLoading } = useTeamsChannel("cla21");

  const match = useRouteMatch();

  const literatures: MenuItem[] = data?.map((literature: Response) => {
    const teamsLinks: MenuItem[] = literature.teamsLinks.map((link) => ({
      id: link.name,
      label: link.name.toUpperCase(),
      link: link.teamsLink,
    }));

    return {
      id: literature.groupName,
      label: literature.groupName.toUpperCase(),
      link: `${match.path}/${dashify(literature.groupName)}`,
      subMenuItems: teamsLinks,
    };
  });

  return (
    <>
      <p>
        Questi sono i team per i lettorati del CLA (Centro Linguistico di
        Ateneo) per l'anno 2021/2022.
      </p>
      {isLoading ? (
        <Loader />
      ) : (
        isSuccess && <InnerMenu menuItems={literatures} hasChildren />
      )}
   </>
  );
};

export default Cla;
