import React, { useEffect, useState } from "react";
import { StudentFormProps, StudentFormData } from "./types";
import { Form, Button } from "react-bootstrap";
import useUpdateFreshman from "../../hooks/useFreshman";
import useDeleteStudent from "../../hooks/useDeleteStudent";
import useStudentForm from "../../hooks/useStudentForm";
import ConfirmDeleteModal from "../confirmDeleteModal";
import "./styles.css";
import Loader from "../loader/Loader";

const StudentEditForm: React.FunctionComponent<StudentFormProps> = ({
  student,
  handleClose,
  renderToast,
  loading,
  setLoading,
}) => {
  const [readOnly, setReadOnly] = useState<boolean>(true);
  const [mutate] = useUpdateFreshman();
  const [mutateDel, { isLoading }] = useDeleteStudent();
  const [confirmDelShow, setConfirmDelShow] = useState<boolean>(false);
  const [, setConfirmDel] = useState<boolean>(false);
  const { register, handleSubmit, errors } = useStudentForm({
    firstname: student.firstName,
    lastname: student.lastName,
    email: student.email,
  });

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  const handleReadOnly = (e: any) => {
    e.preventDefault();
    setReadOnly(false);
  };

  const handleDeleteStudent = () => {
    setConfirmDelShow(true);
  };

  const confirmedDelete = () => {
    mutateDel(student.code, {
      onSuccess: () => {
        renderToast(true, "del");
      },
      onError: () => {
        renderToast(false, "del");
      },
    });
    handleClose();
  };

  const onSubmit = (data: StudentFormData) => {
    mutate(
      {
        ...student,
        firstName: data.firstname,
        lastName: data.lastname,
        email: data.email,
      },
      {
        onSuccess: () => {
          renderToast(true, "upd");
        },
        onError: () => {
          renderToast(false, "upd");
        },
      }
    );
    handleClose();
  };

  const handleCloseDelModal = () => {
    setConfirmDelShow(false);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId="formBasicName">
          <Form.Label>Nome</Form.Label>
          <Form.Control
            readOnly={readOnly}
            type="text"
            placeholder="Inserisci nome"
            name="firstname"
            ref={register}
          />
          {errors.firstname && (
            <p className="text-danger">{errors.firstname.message}</p>
          )}
        </Form.Group>
        <Form.Group controlId="formBasicLastname">
          <Form.Label>Cognome</Form.Label>
          <Form.Control
            readOnly={readOnly}
            type="text"
            placeholder="Inserisci cognome"
            name="lastname"
            ref={register}
          />
          {errors.lastname && (
            <p className="text-danger">{errors.lastname.message}</p>
          )}
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            readOnly={readOnly}
            placeholder="Inserisci email"
            name="email"
            ref={register}
          />
          {errors.email && (
            <p className="text-danger">{errors.email.message}</p>
          )}
        </Form.Group>
        {/*   {unsigned === false  || } */}
        <Form.Group controlId="formBasicStudentId">
          <Form.Label>Matricola</Form.Label>
          <Form.Control
            readOnly={readOnly}
            type="text"
            placeholder="Inserisci matricola"
          />
        </Form.Group>
        {confirmDelShow ? (
          <ConfirmDeleteModal
            show={confirmDelShow}
            handleClose={handleCloseDelModal}
            confirm={setConfirmDel}
            confirmedDelete={confirmedDelete}
          />
        ) : (
          <div className="d-flex justify-content-end">
            {readOnly ? (
              <Button className="w-25 mr-2" onClick={handleReadOnly}>
                Modifica
              </Button>
            ) : (
              <Button className="w-25 mr-2" type="submit">
                Salva
              </Button>
            )}
            <Button
              className="w-25"
              variant="secondary"
              onClick={handleDeleteStudent}
            >
              Elimina
            </Button>
          </div>
        )}
      </Form>
    </>
  );
};

export default StudentEditForm;
