import { Context } from "./../components/contextList/types";
import { useMutation, queryCache } from "react-query";
import authInstance from "../API/AuthInstance";

const { REACT_APP_API } = process.env;

export default function useCreateContext() {
  return useMutation(
    async (context: Context) => {
      const { data } = await authInstance.post(
        `${REACT_APP_API}/team-channel`,
        context
      );
      return data;
    },
    {
      onSuccess: (context) => {
        queryCache.invalidateQueries(context.groupName);
      },
    }
  );
}
