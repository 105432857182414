import { UpdateModalProps } from "./types";
import { Modal } from "react-bootstrap";
import UpdateContextForm from "../updateContextForm";
import React from "react";
import "./styles.css";

const UpdateModal: React.FunctionComponent<UpdateModalProps> = ({
  show,
  handleClose,
  editItem,
  choosenOpt,
  selectedId,
  renderToast,
  loading,
  setLoading,
}) => {
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modifica Contesto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UpdateContextForm
            loading={loading}
            setLoading={setLoading}
            selectedId={selectedId}
            renderToast={renderToast}
            choosenOpt={choosenOpt}
            editItem={editItem}
            show={show}
            handleClose={handleClose}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdateModal;
