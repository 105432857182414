import { Alert } from "react-bootstrap";
import HomeProps from "./types";
import { NavLink } from "react-router-dom";
import React from "react";

const Home: React.FunctionComponent<HomeProps> = () => {
  return (
    <>
      <h3> Informazioni sulla didattica 2023/2024 </h3>
      <p> Le informazioni sulla modalità di erogazione della didattica per l'a.a. 2023/2024
      sono disponibili in questa{" "}
      <a href="https://www.unich.it/avvisi/disposizioni-sulle-modalita-di-erogazione-delle-attivita-didattiche-aa-20232024">pagina</a>.
      </p>    

      <p>Accesso alla teledidattica:
      	<ul>
  	  <li> gli studenti e le studentesse aderenti all'iniziativa{" "}
	   <a href="https://www.unich.it/pa110elode">PA 110 e lode</a> sono automaticamente
	   autorizzati alla didattica a distanza ed inseriti nei team del proprio anno di corso.
	   Per essere inseriti in ulteriori team (ad esempio per gli esami a scelta) inviare una email a 
           <a href="mailto:didatticaadistanza@unich.it"> didatticaadistanza@unich.it</a> . 
          </li>
          <li> gli studenti e le studentesse con invalidità o disabilità ricevono al perfezionamento
	   dell'iscrizione una email con le istruzioni per richiedere la didattica a distanza.
	   Per essere inseriti in ulteriori team (ad esempio per gli esami a scelta) inviare una email a 
           <a href="mailto:didatticaadistanza@unich.it"> didatticaadistanza@unich.it</a> . 
          </li>
        </ul>
      </p>
      <p>Accesso al materiale didattico:
        <ul>
          <li> le studentesse gestanti dal settimo mese di gravidanza e sino ai 3 mesi successivi al parto,
	    o con diagnosi di gravidanza a rischio, certificata dal medico specialista possono inviare la
	    richiesta di accesso al materiale didattico tramite questo <a href="https://forms.office.com/e/z3qA5VDbSw">form</a>.
	  </li>
	  <li> gli studenti e le studentesse cosiddetti/e indipendenti, come da regolamento tasse e contributi universitari,
	    ovvero che abbiano residenza esterna all’unità abitativa della famiglia di origine - in alloggio non di proprietà
	    di un suo membro - da almeno due anni e abbiano redditi propri derivanti da lavoro dipendente o assimilato - non
	    prestato alle dipendenze di un familiare - fiscalmente dichiarati negli ultimi due anni e non inferiori a 9.000,00 euro annui,
	    possono inviare la
            richiesta di accesso al materiale didattico tramite questo <a href="https://forms.office.com/e/yQWVJqr8FX">form</a>.
	  </li>
        </ul>    
      </p>

     <p> La teledidattica è erogata tramite la piattaforma Microsoft
        Teams, utilizzabile sia con un browser all&apos;indirizzo{" "}
        <a href="http://teams.microsoft.com">teams.microsoft.com</a> (solo
        Chrome ed Edge sono supportati) sia con
        l&apos;applicazione specifica che si può scaricare da{" "}
        <a href="http://teams.microsoft.com/downloads">
          teams.microsoft.com/downloads
        </a>
        . L&apos;applicazione Microsoft Teams è inoltre già integrata in Office
        365.
      </p>

      <p>I docenti possono visualizzare l'elenco degli studenti autorizzati alla DAD nella pagina{" "}
      <a href="https://zeus.unich.it/studentidad/">zeus.unich.it/studentidad/</a>.
      </p>

{/*      <h3> Informazioni sulla didattica 2021/2022 </h3>

      <Alert variant="primary">
        <span className="text-danger font-weight-bold">AVVISO per gli studenti</span>
           <p>A partire dal 1 maggio 2022 è stata ripristina la normale capienza nelle aule al 100% e non è più necessario
           l'utilizzo dell'app per la prenotazione del posto in aula.
           </p>
	   <p>
	   Rimane invariata la modalità di erogazione delle attività didattiche, che continueranno ad essere erogate in
	   presenza e contemporaneamente sarà garantita la fruizione a distanza sulla piattaforma Teams di Ateneo.
	   </p>
	   <p>Pur non sussistendo un obbligo specifico di utilizzo dei dispositivi individuali di protezione delle vie
           respiratorie (mascherine FFP2), se ne raccomanda l'uso all'interno delle aule ed in generale negli ambienti
           chiusi in cui si trovino più persone.
           </p>
      </Alert>
      <p>
        Le lezioni dell'anno 2021/2022 si terranno in modalità blended, cioè
	con la possibilità di frequentare in presenza
	(fino alla saturazione delle capienze delle aule)  
	e contemporaneamente con la possibilità di partecipare alla lezione
	da remoto.
        Ulteriori informazioni sulla modalità di inizio delle lezioni sono disponibili
	nell'<a href="https://www.unich.it/avvisi/nuovi-aggiornamenti-sulle-modalita-di-erogazione-della-didattica">avviso</a>.
     nell'<a href="https://www.unich.it/avvisi/ultime-disposizioni-lo-svolgimento-della-didattica-degli-esami-e-delle-sessioni-di-laurea">avviso</a>. 
      </p>
  */}
      <p>
        I calendari delle lezioni sono 
        consultabili alla pagina{" "}
        <a href="https://www.unich.it/sua">www.unich.it/sua</a>.
      </p>
 {/*     <p>
        I calendari delle lezioni sono in corso di definizione, e saranno
	consultabili alla pagina{" "}
        <a href="https://www.unich.it/sua">www.unich.it/sua</a>.
      </p>      
      <p>
        Per frequentare le lezioni in presenza è disponibile una{" "}
        <NavLink to="app-prenotazione">app</NavLink> che consente di prenotare un posto
	in aula per ciascuna settimana
	di lezione, sino alla <strong>capienza massima</strong> individuata in osservanza
	delle vigenti disposizioni di sicurezza anticontagio. Per frequentare le lezioni in
	presenza è <strong>obbligatorio</strong> prenotarsi con
	l'<NavLink to="app-prenotazione">app</NavLink> e{" "}
        <a href="https://www.mur.gov.it/it/news/lunedi-09082021/green-pass-obbligatorio-attivita-presenza-universita-e-afam">possedere la certificazione verde COVID-19
	(Green Pass)</a>.
      </p>

      <Alert variant="primary">
        <span className="text-danger font-weight-bold">AVVISO per i docenti</span> Alla pagina{" "}
        <a href="http://teledidattica.unich.it/prenotazioni/">http://teledidattica.unich.it/prenotazioni/</a> è
        possibile consultare l'elenco degli studenti prenotati per le lezioni in presenza fino al 07/05/2022.
      </Alert>
 */}     
      <p>
        Si invitano tutti gli studenti, i docenti e il personale
        tecnico-amministrativo a prendere visione delle{" "}
        <a href="https://zeus.unich.it/teledidattica19/doc/didattica_a_distanza_prescrizioni_comportamentali.pdf">
          PRESCRIZIONI COMPORTAMENTALI
        </a>{" "}
        delle attività didattiche a distanza.
      </p>
      <h3>Creazione account Microsoft</h3>
      <p>
	Per partecipare alla didattica a distanza è necessario avere un account Microsoft collegato all'email di
	Ateneo (@studenti.unich.it oppure @unich.it). Questo vale sia per i docenti che per gli studenti.
	Anche quando si disponga già di un account Microsoft privato collegato ad altra email, è comunque necessario
	crearne uno nuovo collegato alla propria email di Ateneo.
      </p>
      <p>
	Per la creazione dell'account Microsoft consultare la sezione{" "}
        <NavLink to="account">Account teledidattica</NavLink>.
      </p>
      <h3>Installazione pacchetto Microsoft Office</h3>
      <p>
        Il personale UdA e gli studenti possono scaricare gratuitamente il pacchetto
	Microsoft Office (dopo aver creato un account). Le informazioni per scaricare
	il pacchetto sono disponibili nella sezione{" "}
	<NavLink to="account">Account teledidattica</NavLink>.
      </p>
      <h3>Accesso alle lezioni on-line</h3>
      <h5>Istruzioni per gli studenti</h5>
      <p>
        Una volta creato l&apos;account, sarà sufficiente cliccare sul link
        dell&apos;insegnamento, che trovate nella sezione{" "}
        <NavLink to="lezioni-online">Lezioni</NavLink>, per entrare
        nella classe virtuale. In prossimità dell&apos;orario previsto per la
        lezione, gli studenti troveranno un post con il pulsante Partecipa,
        tramite il quale potranno prendere parte alla lezione.
      </p>
      <Alert variant="danger">
        <span className="font-weight-bold"> IMPORTANTE</span>: prima
	di accedere alla lezione, gli studenti
        <span className="font-weight-bold"> DEVONO</span> disabilitare webcam e
        microfono. È consentito allo studente riattivare il microfono nel
        momento in cui vuole porre delle domande al docente.
      </Alert>
      <h5>Istruzioni per i docenti</h5>
      <p>
        Prima di tutto occorre entrare nel team dedicato al proprio
        insegnamento. È sufficiente avviare Teams per vedere tutti i team di
	cui si fa parte.
      </p>
      <p>
        I team con gli insegnamenti 2021/2022 verranno creati nei prossimi giorni
	dal gruppo di lavoro della didattica a distanza.
      </p>
      <Alert variant="danger">
        <span className="font-weight-bold"> IMPORTANTE</span>: i docenti NON
        devono creare i team in maniera autonoma. Chi non ha ancora un team
        attivato ma vuole fare pratica, può utilizzare l&apos;insegnamento di
        test che vedete sotto.
      </Alert>
      <p>
        La sezione{" "}
        <NavLink to="tutorial">Tutorial</NavLink> contiene delle brevi istruzioni
	e dei video per avviare e svolgere una lezione online. Ulteriori
	informazioni sono disponibili nelle{" "}
        <NavLink to="faq">FAQ</NavLink>.
      </p>
      <h3>Insegnamento di test</h3>
      <p>
        I docenti e gli studenti curiosi di vedere le classi virtuali possono
        collegarsi all&apos;
        <a href="https://teams.microsoft.com/dl/launcher/launcher.html?url=%2F_%23%2Fl%2Fteam%2F19%3Ab945fc8550cf4606a368c03eee075806%40thread.tacv2%2Fconversations%3FgroupId%3Dfbf56c0e-5091-4b67-b35f-3a44c554725e%26tenantId%3D41f8b7d0-9a21-415c-9c69-a67984f3d0de&type=team&deeplinkId=1305c745-ec3f-47ed-9a8c-76183d18d3d7&directDl=true&msLaunch=true&enableMobilePage=true&suppressPrompt=true">
          insegnamento di test
        </a>
        .
      </p>
      <h3>Contatti</h3>
      <p>
        Per eventuali problemi, potete inviare una email a
        <a href="mailto:didatticaadistanza@unich.it">
          {" "}
          didatticaadistanza@unich.it
        </a>
      </p>
    </>
  );
};

export default Home;
