import { Alert } from "react-bootstrap";
import InnerMenu from "../../components/innerMenu/InnerMenu";
import Loader from "../../components/loader/Loader";
import MasterProps from "./types";
import { MenuItem } from "../../components/innerMenu/types";
import React from "react";
import { Response } from "../../components/menuPane/types";
import { dashify } from "../../utils";
import { useRouteMatch } from "react-router-dom";
import useTeamsChannel from "../../hooks/useTeamsChannel";

const Master: React.FunctionComponent<MasterProps> = () => {
  const { data, isSuccess, isLoading } = useTeamsChannel("master");

  const match = useRouteMatch();

  const masters: MenuItem[] = data?.map((master: Response) => {
    const teamsLinks: MenuItem[] = master.teamsLinks.map((link) => ({
      id: link.name,
      label: link.name.toUpperCase(),
      link: link.teamsLink,
    }));

    return {
      id: master.groupName,
      label: master.groupName.toUpperCase(),
      link: `${match.path}/${dashify(master.groupName)}`,
      subMenuItems: teamsLinks,
    };
  });
  return (
    <>
      <p>
        Per la creazione di Teams per i master contattare il prof. Fabio
        Fioravanti all&apos;indirizzo{" "}
        <a href="mailto:fabio.fioravanti@unich.it?subject=Creazione%20Teams%20per%20Master">
          fabio.fioravanti@unich.it
        </a>
      </p>
      <Alert variant="primary">
        NOTA: Gli utenti possono essere aggiunti al team e, successivamente, ai
        canali privati soltanto se possiedono un account Microsoft con
        l&apos;email di Ateneo (<code>@studenti.unich.it</code> oppure{" "}
        <code>@unich.it</code>).
      </Alert>
      <Alert variant="primary">
        Utenti esterni senza account Microsoft del nostro ateneo non possono
        essere aggiunti al team ma possono partecipare alle riunioni tramite
        condivisione del link di invito come spiegato nella{" "}
        <a href="doc/Creazione-Team-Seduta_di_Laurea_v04.pdf">
          Guida per la creazione delle sedute di laurea
        </a>{" "}
        (pag 10, 13) accessibile nella sezione dedicata agli{" "}
        <a href="/esami-laurea">esami di laurea</a>.
      </Alert>
      <p>
        Eventuali elenchi di indirizzi email da aggiungere al team,{" "}
        <span className="ont-weight-bold">
          già corrispondenti ad account Microsoft d&apos;ateneo
        </span>
        , vanno inviati dal Coordinatore o suo delegato a{" "}
        <a href="mailto:fabio.fioravanti@unich.it?subject=%5bTEAMS%5d%20Richiesta%20inserimento%20Master">
          Fabio Fioravanti
        </a>
        . Per rendere più agevole il lavoro di inserimento ogni riga
        dell&apos;elenco deve includere un solo indirizzo email, senza altre
        informazioni. Ad esempio
      </p>
      <ul>
        <li>fabio.fioravanti@unich.it</li>
        <li>nome.cognome@unich.it</li>
      </ul>
      <p>
        In questa fase le richieste di inserimento pervenute tramite canali
        diversi (p.e Teams) potrebbero essere ignorate.
      </p>
      <p>
        I team dei master per l'anno 2019/2020 sono pubblicati alla pagina{" "}
        <a href="https://zeus.unich.it/teledidattica19/#master">https://zeus.unich.it/teledidattica19/#master</a>.
      </p>

      <p>
        Di seguito sono riportati i team che sono stati creati per i master 2020/2021.
      </p>
      {isLoading ? <Loader /> : isSuccess && <InnerMenu menuItems={masters} />}
    </>
  );
};

export default Master;
