import React, { useMemo } from "react";

import { Alert } from "react-bootstrap";
import { Course } from "../../components/innerMenu/types";
import { CourseOfStudy } from "../../interfaces/courseOfStudyType";
import ExamsProps from "./types";
import Loader from "../../components/loader/Loader";
import MenuPane from "../../components/menuPane/MenuPane";
import { NavLink } from "react-router-dom";
import { dashify } from "../../utils";
import useExams from "../../hooks/useExams";
import { useRouteMatch } from "react-router-dom";

const Exams: React.FunctionComponent<ExamsProps> = () => {
  const match = useRouteMatch();
  const { data, isSuccess, isLoading } = useExams();

  const exams = useMemo(() => {
    if (data !== undefined) {
      return data?.map((cos: CourseOfStudy) => {
        const pathOfStudies: Course[] = cos.pathOfStudies.map((path) => {
          return {
            id: path.id,
            label: `${path.description
              .substring(path.description.indexOf("percorso") + 9)
              .toUpperCase()}`,
            courseOfStudyCode: path.courseOfStudyCode,
            link: `${match.path}/${dashify(cos.description)}-${
              cos.code
            }/${dashify(path.description)}`,
            teamsLinks: cos.commonPath
              ? path.lessons
                  .concat(cos.commonPath.lessons)
                  .sort((l1, l2) =>
                    l1.description.localeCompare(l2.description)
                  )
              : path.lessons,
            subMenuItems: [],
          };
        });

        return {
          id: cos.code,
          label: `${cos.code} - ${cos.description.toUpperCase()}`,
          link: `${match.path}/${dashify(cos.description)}-${cos.code}`,
          subMenuItems: pathOfStudies,
          teamsLinks:
            pathOfStudies.length > 1 ? [] : pathOfStudies[0].teamsLinks,
        };
      });
    }
    return [];
  }, [data, match.path]);

  return (
    <>
      <p>
        I team per gli esami dei precedenti anni accademici sono disponibili in queste pagine: 
	<ul>
          <li>
            <a href="https://zeus.unich.it/teledidattica19/#esami21">
              team esami a.a. 2021/2022
            </a>
          </li>
	  <li>
            <a href="https://zeus.unich.it/teledidattica19/#esami20">
              team esami a.a. 2020/2021
            </a>
          </li>
          <li>
            <a href="https://zeus.unich.it/teledidattica19/#esami19">
              team esami a.a. 2019/2020
            </a>
          </li>
        </ul>
      </p>
      <p>
        La somministrazione tanto degli esami orali quanto delle prove scritte
        si avvarrà dell&apos;impiego di Microsoft Teams e del supporto tecnico
        degli incaricati di Ateneo. Le modalità di svolgimento degli esami a
        distanza sono dettagliate nelle Linee guida per docenti e studenti
        disponibili nel tab{" "}
        <NavLink to="linee-guida-esami">Linee guida esami</NavLink>. Potete far
        riferimento alla{" "}
        <a href="https://zeus.unich.it/teledidattica19/doc/Creazione-Team-Appelli-d'esame_v01.pdf">
          guida su come gestire gli appelli d&apos;esame con Teams
        </a>
        .
      </p>
      <p>
        Gli studenti e le studentesse con disabilità e DSA potranno concordare
        eventuali modalità di esame personalizzate solo contattando
        preventivamente il servizio di supporto dedicato.
      </p>
      <Alert variant="primary">
        <span className="text-danger font-weight-bold">SUPPORTO ESAMI</span> I
        docenti che ne avessero necessità potranno richiedere supporto
        nell&apos;organizzazione delle prove d&apos;esame scrivendo a
        <a href="mailto:supportoesamionline@unich.it">
          {" "}
          supportoesamionline@unich.it
        </a>
        .
      </Alert>
    </>
  );
};

export default Exams;
