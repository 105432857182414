import axios from "axios";
import { useQuery } from "react-query";

const { REACT_APP_API } = process.env;

const getContextByCat = async (category: string) => {
  const { data } = await axios.get(`${REACT_APP_API}/team-channel/${category}`);
  return data[0];
};

export default function useGetContext(category: string) {
  return useQuery(category, getContextByCat);
}
