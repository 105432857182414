import React, { useEffect, useState } from "react";
import { TeacherFormProps, TeacherFormData } from "./types";
import { Form, Button } from "react-bootstrap";
import useUpdateTeacher from "../../hooks/useUpdateTeacher";
import useDeleteTeacher from "../../hooks/useDeleteTeacher";
import useTeacherForm from "../../hooks/useTeacherForm";
import ConfirmDeleteModal from "../confirmDeleteModal";
import "./styles.css";
import Loader from "../loader/Loader";

const TeacherEditForm: React.FunctionComponent<TeacherFormProps> = ({
  teacher,
  handleClose,
  renderToast,
  loading,
  setLoading,
}) => {
  const [readOnly, setReadOnly] = useState<boolean>(true);
  const [mutate] = useUpdateTeacher();
  const [mutateDel, { isLoading }] = useDeleteTeacher();
  const [confirmDelShow, setConfirmDelShow] = useState<boolean>(false);
  const [, setConfirmDel] = useState<boolean>(false);
  const { register, handleSubmit, errors } = useTeacherForm({
    username: teacher.studentID,
    firstName: teacher.firstName,
    lastName: teacher.lastName,
    email: teacher.email,
    studentID: teacher.studentID,
    isEnabled: true,
    teamsLink: teacher.teamsLink,
  });

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  const handleReadOnly = (e: any) => {
    e.preventDefault();
    setReadOnly(false);
  };

  const handleDeleteStudent = () => {
    setConfirmDelShow(true);
  };

  const confirmedDelete = () => {
    mutateDel(teacher.id, {
      onSuccess: () => {
        renderToast(true, "del");
      },
      onError: () => {
        renderToast(false, "del");
      },
    });
    handleClose();
  };

  const onSubmit = (data: TeacherFormData) => {
    mutate(
      {
        ...teacher,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        studentID: data.studentID,
        isEnabled: true,
        username: data.studentID,
        teamsLink: data.teamsLink,
      },
      {
        onSuccess: () => {
          renderToast(true, "upd");
        },
        onError: () => {
          renderToast(false, "upd");
        },
      }
    );
    handleClose();
  };

  const handleCloseDelModal = () => {
    setConfirmDelShow(false);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId="formBasicName">
          <Form.Label>Nome</Form.Label>
          <Form.Control
            readOnly={readOnly}
            type="text"
            placeholder="Inserisci nome"
            name="firstName"
            ref={register}
          />
          {errors.firstName && (
            <p className="text-danger">{errors.firstName.message}</p>
          )}
        </Form.Group>
        <Form.Group controlId="formBasicLastname">
          <Form.Label>Cognome</Form.Label>
          <Form.Control
            readOnly={readOnly}
            type="text"
            placeholder="Inserisci cognome"
            name="lastName"
            ref={register}
          />
          {errors.lastName && (
            <p className="text-danger">{errors.lastName.message}</p>
          )}
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            readOnly={readOnly}
            placeholder="Inserisci email"
            name="email"
            ref={register}
          />
          {errors.email && (
            <p className="text-danger">{errors.email.message}</p>
          )}
        </Form.Group>
        <Form.Group controlId="formBasicStudentId">
          <Form.Label>Matricola</Form.Label>
          <Form.Control
            readOnly={true}
            type="text"
            placeholder="Inserisci matricola"
            name="studentID"
            ref={register}
          />
        </Form.Group>
        <Form.Group controlId="formBasicStudentId">
          <Form.Label>Link di Teams</Form.Label>
          <Form.Control
            readOnly={readOnly}
            type="text"
            placeholder="Inserisci il link di teams"
            name="teamsLink"
            ref={register}
          />
        </Form.Group>
        {confirmDelShow ? (
          <ConfirmDeleteModal
            show={confirmDelShow}
            handleClose={handleCloseDelModal}
            confirm={setConfirmDel}
            confirmedDelete={confirmedDelete}
          />
        ) : (
          <div className="d-flex justify-content-end">
            {readOnly ? (
              <Button className="w-25 mr-2" onClick={handleReadOnly}>
                Modifica
              </Button>
            ) : (
              <Button className="w-25 mr-2" type="submit">
                Salva
              </Button>
            )}
            <Button
              className="w-25"
              variant="secondary"
              onClick={handleDeleteStudent}
            >
              Elimina
            </Button>
          </div>
        )}
      </Form>
    </>
  );
};

export default TeacherEditForm;
