import { LessonExam, Lesson } from "../components/lessonsExamsList/types";
import { useMutation, queryCache } from "react-query";
import authInstance from "../API/AuthInstance";

export default function useCreateLessonFinal() {
  return useMutation<LessonExam, Lesson>(
    async (lesson: Lesson) => {
      const { data } = await authInstance.post(`/lesson`, lesson);
      return data;
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries("getLessonExam");
      },
    }
  );
}
