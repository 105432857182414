import * as React from "react";

import { ListGroup, ListGroupItem } from "react-bootstrap";

import { TeamsLinksProps } from "./types";

const TeamsLinks: React.FunctionComponent<TeamsLinksProps> = ({ menuItem, filter }) => {
  return (
    <ListGroup>
      {menuItem.teamsLinks?.filter((link) => filter == 'lesson' ? link.islesson : link.isexam ).map((link) => (
        <ListGroupItem key={link.code} as="a" href={link.teamsLink}>
          {link.description}
        </ListGroupItem>
      ))}
    </ListGroup>
  );
};

export default TeamsLinks;
