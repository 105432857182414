import React from "react";
import ToastErrorProps from "./types";
import { Toast } from "react-bootstrap";
import "./styles.css";

const ToastErrorLogin: React.FunctionComponent<ToastErrorProps> = ({
  showModal,
  setShowModal,
}) => {
  return (
    <>
      <Toast
        show={showModal}
        className="toast-wrapper-error"
        onClose={() => setShowModal(false)}
        delay={2000}
        autohide
      >
        <Toast.Body className="toast-body">
          <p>Attenzione dati errati!</p>
        </Toast.Body>
      </Toast>
    </>
  );
};

export default ToastErrorLogin;
