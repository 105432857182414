import { LoginFormData } from "./../screens/login/types";
import { yupResolver } from "@hookform/resolvers";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const useLoginForm = () => {
  const schema = yup.object().shape({
    username: yup.string().required("Campo obbligatorio"),
    password: yup.string().required("Campo obbligatorio"),
  });

  return useForm<LoginFormData>({
    resolver: yupResolver(schema),
  });
};

export default useLoginForm;
