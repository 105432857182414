import { ConfirmationDeleteModalProps } from "./types";
import { Toast } from "react-bootstrap";
import { CheckCircleIcon, XCircleFillIcon } from "@primer/octicons-react";
import React from "react";
import "./styles.css";

const ConfirmationDeleteModal: React.FunctionComponent<ConfirmationDeleteModalProps> = ({
  show,
  handleClose,
  confirmedDelete,
}) => {
  return (
    <div className="d-flex justify-content-end">
      <Toast show={show} className="bg-warning d-flex justify-content-end">
        <Toast.Header closeButton={false}>
          <span> Confermi di voler eliminare lo studente?</span>
          <span
            onClick={confirmedDelete}
            className="mr-2 ml-2 text-success"
            style={{ color: "green" }}
          >
            <CheckCircleIcon size={24} />
          </span>
          <span onClick={handleClose} className="mr-2 ml-2 text-danger">
            <XCircleFillIcon size={24} />
          </span>
        </Toast.Header>
      </Toast>
    </div>
  );
};

export default ConfirmationDeleteModal;
