export const capitalize = (string: string) => {
  return string[0].toUpperCase() + string.slice(1);
};

export const dashify = (string: string) => {
  const formattedString = string.split("- ")[1];

  const str = formattedString ?? string;

  return str
    .replace(/\W+(?!$)/g, "-")
    .toLowerCase()
    .replace(/\W$/, "")
    .toLowerCase();
};
