import { NavLink, Route, useRouteMatch } from "react-router-dom";

import { Alert } from "react-bootstrap";
import InfoRespondusProps from "./types";
import InnerMenu from "../../components/innerMenu/InnerMenu";
import React from "react";

const InfoRespondus: React.FunctionComponent<InfoRespondusProps> = () => {
  const match = useRouteMatch();

  return (
    <>
      <h4 className="text-uppercase font-weight-bold"> Informazioni sui dati raccolti dal sistema di proctoring Respondus </h4>

      <p>Il sistema di
      proctoring Respondus utilizzato dall’Ateneo per lo svolgimento
      degli esami scritti a distanza è costituito da due componenti:
      </p><p>
       <ul>
        <li>LockDown Browser</li>
        <li>Respondus Monitor.</li>
       </ul>
      </p>
      <p></p>

      <h5 className="text-uppercase font-weight-bold">LockDown Browser</h5>

      <p>LockDown Browser è
      un software che &quot;blocca&quot; un computer durante un esame
      online svolto su una delle piattaforme Moodle di Ateneo (
      <a href="https://fad.unich.it">fad.unich.it</a> e{" "} 
      <a href="https://elearning.unich.it">elearning.unich.it</a>).
      </p>
      <p>Prima di essere
      installato nel computer, LockDown Browser richiede allo studente di
      accettare le condizioni d’uso.</p>
      <p>LockDown Browser non
      richiede di creare un account e, nella maggior parte dei casi, non
      raccoglie o elabora informazioni personali. Tuttavia, ci sono due
      casi in cui LockDown Browser può elaborare informazioni personali.
      Il primo è se lo studente sceglie di utilizzare il &quot;Centro
      assistenza&quot; per risolvere un problema tecnico con LockDown
      Browser, come ad esempio la connettività Internet. Una seconda
      situazione in cui LockDown Browser può elaborare informazioni
      personali è quando uno studente utilizza la funzione di &quot;uscita
      anticipata&quot; per terminare un esame prima che sia completato.
      Allo studente viene richiesto di fornire un motivo per interrompere
      anticipatamente la sessione, che viene memorizzato ed è a
      disposizione del docente per la revisione. Respondus non fa altro uso
      di queste informazioni personali.</p>
      <h5 className="text-uppercase font-weight-bold">Respondus Monitor</h5>
      <p>LockDown Browser
      viene utilizzato insieme al software Respondus Monitor.</p>
      <p>Respondus Monitor è
      un'applicazione web a cui si accede dal sito Moodle di Ateneo
      utilizzato per l’esame (fad.unich.it e elearning.unich.it).
      Respondus Monitor registra dei video utilizzando la webcam e il
      microfono dello studente durante un esame online. Respondus Monitor
      può registrare anche altri dati relativi allo svolgimento dell’esame
      come, ad esempio, il tempo impiegato da uno studente per rispondere
      ad una domanda specifica dell’esame. Le registrazioni e gli altri
      dati della sessione d'esame vengono elaborati automaticamente, e al
      docente vengono fornite informazioni di sintesi, ad esempio se lo
      studente si è assentato dal video durante la sessione d'esame.
      Inoltre, Respondus Monitor tiene continuamente traccia delle
      applicazioni e dei processi in esecuzione sul computer dello studente
      durante una sessione d'esame.</p>
      <p>Respondus Monitor,
      per interagire con il sito Moodle, utilizza un plugin che è stato
      installato dai sistemisti nei siti Moodle di Ateneo. Respondus
      Monitor non richiede né agli studenti, né ai docenti di creare un
      account poiché sfrutta il meccanismo di &quot;single sign-on&quot;
      per l’autenticazione, e lo username utilizzato viene passato e
      memorizzato nei server di Respondus.</p>
      <p>Durante una sessione
      di esame con Respondus Monitor vengono elaborati automaticamente una
      serie di dati. Il video registrato dalla webcam passa attraverso una
      fase di &quot;post-elaborazione&quot; automatica che utilizza
      tecniche di riconoscimento facciale per determinare se lo studente è
      rimasto davanti alla webcam, se più persone appaiono nel video e se
      la persona nel video differisce dalla persona che ha iniziato
      l'esame. A tal fine viene creato un modello temporaneo di
      identificatori facciali durante l'elaborazione automatica, e tale
      modello viene utilizzato nella memoria temporanea del server che
      esegue il software Respondus Monitor per determinare se lo studente
      che ha iniziato l'esame è diverso dalla persona presente nel video
      in elaborazione. Il modello non viene salvato nel database o nei
      server e viene cancellato al termine dell'elaborazione, che avviene
      entro due (2) giorni dal completamento dell'esame. 
      </p>
      <p>Il personale di
      Respondus non esamina né analizza le registrazioni, ad eccezione di
      eventuali richieste per risolvere problemi tecnici, migliorare le
      prestazioni del sistema, modificare Respondus Monitor, indagare su
      violazioni delle condizioni d’uso o in forza di disposizioni di
      legge.</p>
      <p>Respondus Monitor
      elabora altri dati della sessione di esame, quali ad esempio: la data
      e l'ora in cui l'utente inizia e termina una sessione di esame; il
      tempo in cui viene data risposta a ciascuna domanda d'esame; quanto
      tempo viene dedicato a ciascuna domanda d'esame; se e quando viene
      modificata una risposta a una domanda d'esame; la qualità della
      connessione Internet dell'utente durante la sessione d'esame
      (compreso il tempo e la durata di eventuali disconnessioni Internet);
      le attività del mouse, della tastiera e dello schermo; la qualità
      della registrazione video (in termini di illuminazione, contrasto,
      movimento) e la qualità della registrazione audio.</p>
      <p>I dati aggiuntivi,
      così come le registrazioni, sono a disposizione dei docenti per
      rivedere, valutare e analizzare il comportamento degli studenti
      durante l’esame.</p>
      <p>I dati degli
      studenti possono essere aggregati ed i dati dei singoli studenti
      possono essere confrontati con i dati aggregati per cercare dei
      pattern comuni o delle anomalie, ad esempio se uno studente ha
      trascorso un tempo insolitamente lungo per rispondere a una domanda
      rispetto ad altri studenti.</p>
      <p>Le informazioni di
      sintesi per ciascuna sessione d'esame sono messe a disposizione del
      docente, inclusa una &quot;timeline&quot; in cui vengono annotati gli
      eventi importanti (ad esempio: quando è stata data una risposta a
      una domanda d'esame; quando si è verificata una disconnessione da
      Internet; quando sono apparse più persone nel video). Al docente
      vengono forniti anche dati aggregati (ad esempio: la durata media del
      tempo trascorso dagli studenti su una domanda d'esame; il tempo medio
      trascorso sull'intero esame; la data/ora media di inizio della
      sessione d'esame).</p>
      <p>Viene generato un
      valore complessivo per una sessione di esame che aiuta il docente a
      determinare il rischio che si siano verificate violazioni dell'esame.
      Anche in questo caso, queste analisi vengono generate automaticamente
      dall'applicazione Respondus Monitor.</p>
      <p>Respondus Monitor
      interagisce con il sito Moodle di Ateneo per visualizzare altre
      informazioni personali, ad esempio i voti, all'interno
      dell'applicazione Respondus Monitor. Queste informazioni vengono
      ottenute in tempo reale dal sito Moodle di Ateneo e non vengono
      elaborate o archiviate da Respondus Monitor.</p>
      <p>A seconda delle
      impostazioni dell'esame selezionate dal docente, agli studenti
      potrebbe essere richiesto di mostrare alla webcam un documento
      d’identità a cui verrà scattata una foto. L'immagine della foto
      viene trasmessa in modo sicuro al server Respondus Monitor
      utilizzando il protocollo https e quindi archiviata sul server in
      formato crittografato. Respondus non effettua ulteriori elaborazioni
      o analisi di questi dati.</p>
      <p>I dati collezionati
      da Respondus Monitor sono conservati per 5 anni, salvo differenti
      termini previsti dalla normativa. Respondus utilizza i
      server AWS (Amazon Web Services) che si trovano negli Stati Uniti.
      Respondus Monitor utilizza i seguenti metodi per limitare l'accesso a
      informazioni o dati personali (quali ad esempio i video delle
      registrazioni degli studenti o altre informazioni personali):
      </p><p>
       <ul>
        <li>Respondus
        Monitor utilizza i protocolli crittografici standard SSL (Secure
        Socket Lay) o TLS (Transport Layer Security) per trasferire le
        informazioni.</li>
        <li>È possibile
        accedere alle informazioni che identificano gli studenti (ad
        esempio: il nome, il voto, il nome del corso e le foto che mostrano
        il documento d’identità) solo attraverso i siti Moodle di Ateneo
        con i quali si svolge l’esame (fad.unich.it e elearning.unich.it).</li>
        <li>Solo i docenti
        e gli amministratori dei siti Moodle di Ateneo possono visualizzare
        contemporaneamente i video registrati dagli studenti e le
        informazioni che identificano gli studenti.</li>
       </ul>
      </p>
      <p></p>
      <p>Maggiori
      informazioni sono consultabili nell’<a href="https://web.respondus.com/tou-monitor-student">Informativa 
      (in inglese)</a> e
      nella sezione <NavLink to="tutorial">Tutorial</NavLink> è disponibile un video tutorial con le
      istruzioni per gli studenti su come installare Respondus per sostenere un esame. 
      </p>
    </>
  );
};

export default InfoRespondus;
