import { Alert } from "react-bootstrap";
import AccountProps from "./types";
import React from "react";

const Account: React.FunctionComponent<AccountProps> = () => {
  return (
    <>
     <h3>Creazione account Microsoft</h3>
      <p>
        Per partecipare alla didattica a distanza è necessario avere un account Microsoft collegato all'email di
        Ateneo (@studenti.unich.it oppure @unich.it). Questo vale sia per i docenti che per gli studenti.
        Anche quando si disponga già di un account Microsoft privato collegato ad altra email, è comunque necessario
        crearne uno nuovo collegato alla propria email di Ateneo. 
      </p>
    <h5>Account per gli studenti</h5>
      <p>
        Gli studenti riceveranno (entro 48 dal completamento dell'immatricolazione)
        una email all'indirizzo comunicato nella registrazione al portale{" "}
        <a href="https://udaonline.unich.it">udaonline.unich.it</a> con
        le istruzioni per creare un account Microsoft.
      </p>
     <h5>Account per il personale docente e T/A di Ateneo</h5>
      <p>
        Per creare l'account Microsoft occorre inviare la richiesta
        a <a href="mailto:didatticaadistanza@unich.it"> didatticaadistanza@unich.it</a> utilizzando il proprio
        account di posta di Ateneo (@unich.it).
      </p>
      <h5>Account Docenti a contratto</h5>
      <p>
        Le richieste di creazione account per i docenti a contratto non devono essere inviate dai docenti
        stessi ma dai presidenti dei corsi di studi a <a href="mailto:didatticaadistanza@unich.it"> didatticaadistanza@unich.it</a>.
      </p>
     <h3>Installazione pacchetto Microsoft Office</h3>
      <p>
        Il personale UdA e gli studenti possono scaricare gratuitamente il pacchetto
        Microsoft Office (dopo aver creato un account) dalla pagina{" "}
        <a href="https://myaccount.microsoft.com">myaccount.microsoft.com</a>{" "}
        effettuando l'accesso con il proprio account Microsoft{" "}
        <code>@unich.it</code>{" "} o{" "} <code>@studenti.unich.it</code>{" "}
        e selezionando "App di Office".
      </p>
    </>
  );
};

export default Account;
