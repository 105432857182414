import { TeacherFormData } from "../components/createTeacherForm/types";
import { yupResolver } from "@hookform/resolvers";
import { useForm } from "react-hook-form";
/* const [mutate, result] = useUpdateFreshman() */
import * as yup from "yup";

const useTeacherForm = (defaultValues: TeacherFormData) => {
  const schema = yup.object().shape({
    firstName: yup.string().required("Campo obbligatorio"),
    lastName: yup.string().required("Campo obbligatorio"),
    email: yup
      .string()
      .email("Il campo deve essere una email")
      .required("Campo obbligatorio"),
    studentID: yup.string().required("Campo obbligatorio"),
    teamsLink: yup.string().required("Campo obbligatorio"),
  });

  return useForm<TeacherFormData>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });
};

export default useTeacherForm;
