import { NavLink, Route, useRouteMatch } from "react-router-dom";

import { Alert } from "react-bootstrap";
import ExamsGuidelinesProps from "./types";
import InnerMenu from "../../components/innerMenu/InnerMenu";
import React from "react";

const ExamsGuidelines: React.FunctionComponent<ExamsGuidelinesProps> = () => {
  const match = useRouteMatch();

  const PartOne = () => (
    <div>
      <p>
        Per quanto non espressamente previsto nelle seguenti linee guida, si
        rimanda all’applicazione di quelle già precedentemente diramate. Sia per
        le prove scritte che per quelle orali deve essere garantita la
        pubblicità dello svolgimento. La piattaforma utilizzata viene comunicata
        agli studenti in anticipo, in modo pubblico, per permettere di
        partecipare o assistere alla prova d’esame, in particolare per gli
        studenti che frequentino il singolo insegnamento.
      </p>

      <h5 className="text-uppercase font-weight-bold">Modalità d’esame</h5>

      <p>
        In considerazione delle diverse modalità di somministrazione, il docente
        può rimodulare l’esame in altra forma e modificarne la durata, in deroga
        a quanto previsto nel Syllabus e previa comunicazione agli studenti.
      </p>
      <p>
        Per la composizione della commissione esaminatrice, si seguono le usuali
        procedure, inclusa la possibilità di procedere per sottocommissioni. Si
        ricorda che la commissione d’esame è composta da almeno due docenti.
      </p>
      <p>
        Lo studente che partecipa all’esame accetta implicitamente di apparire
        in videoconferenza. È inoltre esclusiva facoltà del docente procedere
        alla registrazione della prova d’esame, che verrà successivamente
        rimossa in accordo alla normativa vigente.
      </p>

      <h5 className="text-uppercase font-weight-bold">
        Identificazione dello studente
      </h5>

      <p>
        L’identificazione dello studente deve avvenire nel rispetto delle
        esigenze di privatezza di cui alla disciplina GDPR e comunicata in
        anticipo allo studente.&nbsp; Si suggerisce al docente di operare una
        delle seguenti scelte alternative:
      </p>
      <ul>
        <li>
          procedere in via preferenziale all’identificazione ricorrendo alla
          foto dello studente visibile in ESSE3 nella pagina degli appelli (è
          possibile avviare il browser contemporaneamente al software di
          videoconferenza oppure ricorrere ad un secondo supporto informatico
          come lo smartphone);
        </li>
        <li>
          far esibire allo studente il documento di identità in un contesto
          protetto, in modo tale che non sia visibile agli altri studenti,
          evitando quindi la riunione pubblica dell’esame;
        </li>
        <li>
          farsi preventivamente inviare copia del documento di identità tramite
          email oppure mediante caricamento su OneDrive, e quindi procedere in
          sede d’esame all’identificazione.
        </li>
      </ul>

      <h5 className="text-uppercase font-weight-bold">Strumenti informatici</h5>

      <p>
        Per partecipare alla prova, ciascuno studente dovrà essere munito di:
      </p>
      <ul>
        <li>una buona connessione di rete;</li>
        <li>
          account sulla piattaforma tecnologica utilizzata per la prova,
          precedentemente creato;
        </li>
        <li>
          un computer o un tablet o uno smartphone per collegarsi alla
          videoconferenza dotato di webcam e microfono.
        </li>
      </ul>
      <p>
        È caldamente consigliato agli studenti di controllare il corretto
        funzionamento della webcam e del microfono precedentemente all’esame,
        che non potrà essere sostenuto in mancanza della comunicazione audio e
        video. Non è ammesso l’utilizzo di cuffie o auricolari per sostenere
        l’esame.
      </p>
      <p>
        Si richiede agli studenti la massima collaborazione e correttezza nello
        svolgimento della prova d’esame, impegnandosi a sostenerla
        autonomamente, senza ricorrere ad aiuti esterni o documentazione non
        autorizzata. Le prove vanno svolte in assoluto silenzio, a meno di
        essere direttamente interpellati dal docente.
      </p>
      <h5 className="text-uppercase font-weight-bold">
        Candidati con diagnosi di disturbo specifico dell’apprendimento o con
        disabilità certificata
      </h5>
      <p>
        Agli studenti con diagnosi di DSA o con disabilità certificata è
        assicurato lo svolgimento a distanza della prova mediante l’ausilio
        delle misure di supporto normativamente previste.
      </p>
      <p>
        Nel caso in cui sia necessario il ricorso ad un trattamento
        individualizzato, la disciplina legale prevede che gli studenti con
        disabilità possano sostenere le prove d’esame mediante il supporto degli
        ausili loro necessari. Si consente inoltre il trattamento
        individualizzato in favore degli studenti con disabilità per il
        superamento degli esami universitari, previa intesa con il docente della
        materia e con l’ausilio del servizio di tutorato. É consentito, altresì,
        l’impiego di specifici mezzi tecnici in relazione al tipo di disabilità,
        cosi come la possibilità di svolgere prove equipollenti su proposta del
        servizio di tutorato specializzato. Il trattamento individualizzato
        include la previsione di tempi più lunghi per l’effettuazione delle
        prove scritte o grafiche, l’utilizzo di ausili, la presenza di
        assistenti per l’autonomia e la comunicazione o la predisposizione di
        prove equipollenti.
      </p>
      <p>
        Lo studente interessato contatta all’uopo il Servizio d’Ateneo preposto,
        almeno cinque giorni prima della data prevista per la seduta di esame,
        onde consentire di verificare le misure necessarie all’espletamento
        della prova. Il Servizio d’Ateneo preposto trasmette tempestivamente al
        Presidente della Commissione d’esame la richiesta pervenuta e invita a
        predisporre soluzioni d’esame congrue, nonché a fornire le istruzioni e
        il supporto tecnici del caso.
      </p>
    </div>
  );

  const PartTwo = () => (
    <div>
      <p>
        L’esame orale di profitto si svolge in videoconferenza su Microsoft
        Teams.
      </p>

      <p>
        Il docente pianificherà la videoconferenza dandone comunicazione
        pubblica, unitamente alle indicazioni sulla piattaforma utilizzata e
        sulle modalità di partecipazione.
      </p>

      <p>
        Partecipano allo svolgimento del colloquio almeno due membri della
        commissione d’esame. È possibile operare per sottocommissioni in
        videoconferenze distinte, fermo restando il diritto dello studente di
        essere esaminato anche dal Presidente di commissione. Nel caso in cui
        siano istituite più sottocommissioni, al docente responsabile spetta la
        supervisione delle loro attività.
      </p>

      <p>Durante l’esame, gli studenti sono tenuti a:</p>
      <ul>
        <li>
          collocarsi ad una distanza dalla telecamera tale da consentire ai
          docenti di vedere per intero il busto e le braccia;
        </li>
        <li>
          astenersi dall’utilizzare qualsiasi tipo di appunto, libro o strumento
          tecnologico, tranne nel caso in cui ciò sia espressamente consentito
          dal docente responsabile;
        </li>
        <li>
          evitare che, nel medesimo ambiente, siano presenti altre persone o
          monitor per tutta la durata della prova (precisando che il docente
          potrà in qualsiasi momento richiedere una panoramica a 360° tramite
          direzionamento della webcam);
        </li>
        <li>
          seguire le indicazioni del docente responsabile per assicurare il
          regolare svolgimento della prova.
        </li>
      </ul>
      <p>
        Nel caso in cui lo studente violi una o più delle disposizioni di cui ai
        punti precedenti, il docente avrà facoltà di interrompere ed
        eventualmente annullare la prova seduta stante.
      </p>

      <p>
        Il docente gestisce la verifica del profitto secondo le modalità
        ordinarie. Al termine della verifica, comunica il voto allo studente.
        Dopo la conclusione dell’appello, il docente responsabile effettua la
        verbalizzazione online attraverso il ricorso alle usuali procedure.
      </p>
      <p>
        Per lo svolgimento dell’esame, sia i docenti che gli studenti si dotano
        dell’applicativo Microsoft Teams debitamente installato oppure di un
        browser tra quelli supportati.
      </p>
      <p>
        Durante tutto lo svolgimento delle prove d’esame, gli studenti non
        esaminati avranno cura di tenere disattivati la webcam e il microfono.
      </p>
    </div>
  );
  const PartThree = () => (
    <div>
      <h5 className="text-uppercase font-weight-bold">
        Svolgimento in sintesi
      </h5>
      <p>
        Le prove scritte d’esame vengono effettuate dal candidato su supporto
        cartaceo. L’esame si svolge tramite videosorveglianza utilizzando la
        webcam e il microfono del computer dello studente connesso con Microsoft
        Teams. Al termine della prova, lo studente scansiona o fotografa il
        compito, lo mostra alla webcam e poi lo invia al docente. L’intero esame
        è registrato.
      </p>
      <h5 className="text-uppercase font-weight-bold">
        Organizzazione preliminare del docente
      </h5>
      <p>
        Nel caso di esami scritti con un numero elevato di studenti prenotati,
        si consiglia di organizzare più turni, in modo da consentire la adeguata
        videosorveglianza degli svolgimenti. Si ricorda che Microsoft Teams
        permette la visualizzazione contestuale di massimo 9 studenti per
        ciascun componente della commissione. In alternativa, si può suddividere
        la platea degli studenti prenotati in gruppi, assegnando ogni gruppo ad
        una diversa sottocommissione, che si riunisca in parallelo. In tal caso,
        il docente comunica la modalità d’esame in anticipo allo studente,
        insieme al link per partecipare alla sessione gestita dalla
        sottocommissione designata.
      </p>
      <p>Prima della prova il docente:</p>
      <ul>
        <li>
          {" "}
          pianifica la riunione su Microsoft Teams; in caso siano previsti più
          turni o gruppi, predispone un calendario, in modo tale che gli
          studenti sappiano a quale turno e gruppo riferirsi;
        </li>
        <li>
          predispone, tra le seguenti, la modalità prescelta di invio dei file
          da parte degli studenti:
          <ul>
            <li>
              {" "}
              attività <em>Compiti</em> nella piattaforma Moodle;
            </li>
            <li>una cartella riservata nel Team dell’esame;</li>
            <li>invio per email al docente.</li>
          </ul>
        </li>
        <li>
          tramite messaggio dal sito degli appelli su ESSE3 e qualora
          necessario, invita gli iscritti all’appello ad inviare una copia o una
          foto del documento di d’identità (mediante Teams, Onedrive o altri
          strumenti)
        </li>
      </ul>
      <h5 className="text-uppercase font-weight-bold">
        Organizzazione preliminare dello studente
      </h5>
      <p>Prima della prova d’esame, gli studenti avranno cura di:</p>
      <ul>
        <li> installare il software di videosorveglianza previsto;</li>
        <li>
          installare una app per la scansione dell’elaborato e l’invio al
          docente (Office Lens, Google Drive, Adobe Scan o CamScanner. Gli
          studenti dell’ateneo possono attivare gratuitamente la versione
          Premium di CamScanner registrandosi con l’email @studenti.unich.it)
          ovvero predisporre il proprio dispositivo alla riproduzione
          fotografica dell’elaborato e all’invio mail al docente;
        </li>
        <li>
          prima dell’esame, provare a produrre, tramite smartphone, un file
          unico da due o più foto utilizzando l’app per la scansione;
        </li>
        <li>
          disporre di fogli bianchi, di eventuale altro materiale indicato dal
          Presidente della commissione (es. calcolatrice manuale) e di un
          documento di identità;
        </li>
        <li>
          preparare due penne, possibilmente di colore nero e a tratto
          sufficientemente spesso (le scritte effettuate con penne con tratto
          troppo sottile risultano di difficile lettura dopo la loro
          acquisizione);
        </li>
        <li>
          assicurarsi che l’illuminazione dell’ambiente di lavoro sia adeguata a
          consentire al docente la possibilità di una nitida visione di
          monitoraggio;
        </li>
        <li>
          evitare che, nel medesimo ambiente, siano presenti altre persone o
          monitor per tutta la durata della prova (precisando che il docente
          potrà̀ in qualsiasi momento richiedere una panoramica a 360° tramite
          direzionamento della webcam);
        </li>
        <li>
          aver cura che il computer non si blocchi durante la prova e quindi:
          <ul>
            <li>
              disattivare le impostazioni di risparmio energetico ed eventuali
              screensaver;
            </li>
            <li>
              utilizzare il computer servendosi di alimentazione da rete
              elettrica e non da batterie che possono scaricarsi;
            </li>
          </ul>
        </li>
        <li>
          organizzare la postazione di lavoro in modo da consentire la
          visualizzazione di volto, mani e fogli con la webcam (si veda la foto
          sul <a href="img/posizionamento.jpg">corretto posizionamento</a>);
        </li>
        <li>
          la scrivania dello studente deve essere il più possibile sgombra.
        </li>
      </ul>
      <h5 className="text-uppercase font-weight-bold">
        Modalità di svolgimento della prova d’esame
      </h5>
      <p>All’inizio dell’esame il docente:</p>
      <ul>
        <li>
          avvia la riunione sulla piattaforma e la registrazione del meeting.
          Gli studenti dovranno lasciare i microfoni e la webcam accesi durante
          la propria prova, permettendo di individuare eventuali rumori
          sospetti;
        </li>
        <li>
          effettua il riconoscimento degli studenti mediante la foto presente su
          ESSE3 e/o la copia del documento d’identità ove richiesto;
        </li>
        <li>
          verifica il corretto posizionamento dei candidati; si consiglia di far
          in modo che lo studente sia ripreso almeno da un metro di distanza
          dalla webcam (si veda la foto sul{" "}
          <a href="img/posizionamento.jpg">corretto posizionamento</a>).
          Esemplificazione della posizione corretta che dovrebbe apparire sullo
          schermo:
          <ul>
            <li>seduta ad almeno 1 m. dalla webcam;</li>
            <li>mani e foglio visibili;</li>
            <li>smartphone in vista e a schermo rovesciato;</li>
            <li>tastiera distanziata;</li>
            <li>
              scrivania preferibilmente addossata alla parete in modo che lo
              studente non abbia nessuno davanti a sé;
            </li>
          </ul>
        </li>
        <li>
          informa gli studenti che la prova d’esame verrà registrata per tutta
          la sua durata e che, pertanto, dovranno prepararsi in un contesto
          adeguato dove non siano visibili contenuti di rilievo personale o
          sensibile e dove nessun altro, a parte lo studente, possa apparire nel
          video, a tutela della riservatezza dei partecipanti e di chi condivida
          eventualmente gli spazi di vita dello studente. Si preciserà che la
          necessità di registrare è volta a supportare l’obbligo legale degli
          esaminatori di vigilare e garantire il corretto svolgimento delle
          prove. Pertanto, anche ai sensi dell’informativa, il trattamento dei
          dati audio e video si rende necessario, pena l’esclusione dalla prova.
          Le registrazioni saranno conservate con la massima cura per la durata
          strettamente necessaria e potranno essere utilizzate solo al fine di
          verificare a posteriori il corretto svolgimento dell’esame, a tutela
          dell’Ateneo e degli iscritti alla prova, anche in caso di
          contestazioni. In nessun caso le registrazioni saranno rese pubbliche,
          così come i documenti di identità eventualmente richiesti, i quali
          verranno successivamente eliminati in conformità alla normativa
          vigente;
        </li>
        <li>
          informa gli studenti che, durante l’esame, eventuali domande potranno
          essere poste nella videoconferenza in maniera verbale; è possibile
          utilizzare la chat diretta solo nelle ipotesi di problemi di natura
          personale.
        </li>
      </ul>
      <p>Ai fini dello svolgimento dell’esame il docente:</p>
      <ul>
        <li>
          condivide il testo della prova. Ciò può avvenire tramite Moodle
          (attività <em>File</em> o <em>Compito</em>), su Teams o eventualmente
          via email. Lo studente è invitato a ricopiare o stampare il testo e
          poi ad allontanarsi dallo schermo ad una distanza di almeno un metro:
          ciò minimizza sia il rischio di intromissione nel computer che della
          presenza di estranei nella stanza;
        </li>
        <li>
          con la collaborazione degli altri componenti della commissione e dei
          tecnici, il docente monitora gli studenti durante la prova e risponde
          ad eventuali domande verbali (o domande personali in chat);
        </li>
        <li>
          durante la prova può chiedere al candidato (ove lo ritenga necessario)
          di visualizzare la stanza in webcam secondo una visione a 360°.
        </li>
      </ul>
      <p>
        Nel caso di interruzione della connessione a Internet o di interruzione
        del collegamento video o audio per un periodo consistente durante la
        prova, il docente si riserva di considerare la possibile ripetizione
        dell’esame in forma orale. Il colloquio orale ha luogo immediatamente al
        termine della prova scritta.
      </p>
      <p>
        Se lo studente è sorpreso a copiare o assume comportamenti di natura
        scorretta ovvero abbandona la postazione di lavoro prima della
        conclusione della prova scritta, il suo esame verrà annullato, previa
        richiesta di delucidazioni all’interessato.
      </p>
      <p>Durante lo svolgimento dell’esame lo studente:</p>
      <ul>
        <li>
          inserisce all’inizio di ciascuna pagina: cognome, nome, matricola e
          numero di pagina, con aggiunta della firma finale;
        </li>
        <li>
          ha cura che la webcam inquadri in maniera ampia la figura personale e
          lo spazio di lavoro sulla scrivania;
        </li>
        <li>mantiene attivo il microfono del software di videoconferenza;</li>
        <li>
          tiene il cellulare visibile sulla scrivania (schermo capovolto);
        </li>
        <li>garantisce l’assenza di altre persone nella stanza;</li>
        <li>
          evita di provocare qualsiasi rumore, dal momento che la prova si
          svolge a microfoni attivi;
        </li>
        <li>
          in caso di caduta della connessione, si riconnette immediatamente alla
          videoconferenza; in caso ciò risulti impossibile, contatta via chat il
          docente per concordare un’altra data di svolgimento oppure comunica la
          propria volontà di ritiro dalla prova;
        </li>
        <li>
          attende in ogni caso il termine del tempo assegnato senza muoversi dal
          proprio posto.
        </li>
      </ul>
      <h5 className="text-uppercase font-weight-bold">
        Consegna dell’elaborato
      </h5>

      <p>
        All’ora prestabilita, gli studenti verificano di aver inserito in ogni
        pagina dell’elaborato il proprio nome e cognome, la matricola, il numero
        di pagina, la firma finale e, se il docente lo ritiene opportuno, il
        codice di 6 cifre comunicato al termine della prova, poggiano le penne e
        mostrano tutti i fogli in successione avvicinandoli alla webcam, così
        come richiesto dal docente (ad es: “mostrare la pagina 1”, “mostrare la
        pagina 2” con una procedura in parallelo, in quanto la prova è
        registrata).
      </p>
      <p>
        La consegna dell’elaborato avviene tramite scansione (ovvero
        riproduzione fotografica) ed invio. Il tempo a disposizione per queste
        operazioni è di 5 minuti. Coloro che, in assenza di giustificati e
        dimostrabili motivi, non rispettassero i tempi previsti sarebbero
        considerati come ritirati dalla prova.
      </p>
      <p>
        Le scansioni dell’elaborato vengono effettuate attraverso l’applicazione
        precedentemente installata dallo studente, fotografando i fogli e
        producendo un unico file nominato “Cognome_Nome.pdf”.
      </p>
      <p>
        L’elaborato scansionato viene inviato utilizzando esclusivamente lo
        strumento predisposto dal docente e preventivamente comunicato allo
        studente, consegnato nell’intervallo di tempo stabilito e comunque nello
        stretto tempo necessario alla riproduzione fotografica o mediante
        scansione.
      </p>
      <h5 className="text-uppercase font-weight-bold">
        Pubblicazione risultati, visione elaborati e verbalizzazione
      </h5>
      <p>
        Ove il docente maturi perplessità sulla genuinità dell’elaborato, può
        integrare la prova con domande orali. Nel caso si riscontrino
        irregolarità diffuse che mettano in dubbio la correttezza complessiva
        della prova, il docente si riserva di annullarla a carico di tutti gli
        studenti coinvolti.
      </p>
      <p>
        Una volta corretti gli elaborati e data la possibilità agli studenti di
        visionare il compito, qualora non siano previste prove successive, si
        annotano su ESSE3 gli esiti di voto secondo le modalità consuete.
      </p>
    </div>
  );
  const PartFour = () => (
    <div>
      <h5 className="text-uppercase font-weight-bold">
        Lo svolgimento in sintesi
      </h5>
      <p>
        L’esame viene effettuato sulla piattaforma Moodle (
        <a href="https://elearning.unich.it">elearning.unich.it</a> oppure{" "}
        <a href="https://fad.unich.it">fad.unich.it</a>), integrata con
        l’utilizzo del sistema di proctoring Respondus, che permette di svolgere
        la prova a distanza con il computer dello studente in un ambiente
        controllato.
      </p>
      <p>In particolare, Respondus presenta le seguenti caratteristiche:</p>
      <ul>
        <li>
          fornisce un browser assistito da un meccanismo di locking del
          computer, in grado di assicurare che lo studente non possa accedere ad
          altri siti o risorse di comunicazione durante lo svolgimento
          dell’esame;
        </li>
        <li>
          effettua la videosorveglianza tramite la webcam e il microfono in
          dotazione al computer dello studente, per assicurare che - nel
          medesimo ambiente dove lo studente svolga la prova e per tutta la sua
          durata - non siano presenti altre persone o mezzi di comunicazione;
        </li>
        <li>
          effettua un’analisi automatica delle registrazioni video (con tecniche
          di intelligenza artificiale), rendendole disponibili al docente.
        </li>
      </ul>
      <p></p>
      <p>Maggior informazioni su Respondus sono consultabili <NavLink to="/info-respondus">in questa pagina</NavLink>.
      </p>
      <p>
        Laddove lo studente non accetti la sorveglianza mediante il sistema di
        proctoring Respondus, dovrà darne comunicazione al docente almeno 2
        giorni prima della data dell’appello e concordare una modalità
        alternativa, che sarà singolarmente progettata con il docente
        affidatario dell’insegnamento.
      </p>
      <h5 className="text-uppercase font-weight-bold">
        Organizzazione preliminare del docente
      </h5>
      <p>Prima della prova il docente:</p>
      <ul>
        <li>
          {" "}
          richiede la creazione di un corso sulla piattaforma Moodle (solo se
          NON DISPONE già del corso su{" "}
          <a href="https://elearning.unich.it">
            elearning.unich.it
          </a> oppure <a href="https://fad.unich.it">fad.unich.it</a>);
        </li>
        <li>predispone l’esame utilizzando l’attività Quiz su Moodle;</li>
        <li>attiva Respondus sul Quiz;</li>
        <li>
          avvisa gli studenti della modalità d’esame Moodle+Respondus ed invia
          il link al Quiz predisposto per l’esame (tramite messaggio dal sito
          degli appelli su ESSE3 oppure inviando una comunicazione nel Team
          dell’insegnamento);
        </li>
        <li>
          informa gli studenti che la prova d’esame verrà registrata per tutta
          la sua durata e che, pertanto, dovranno aver cura - per quanto
          possibile - di predisporre un contesto di lavoro adeguato, dove non
          siano visibili contenuti di rilievo personale o sensibile e dove
          nessun altro, a parte lo studente, possa apparire nel video, a tutela
          della riservatezza dei partecipanti e di chi condivida eventualmente
          gli spazi di vita dello studente. Si preciserà che la necessità di
          registrare è volta a supportare l’obbligo legale degli esaminatori di
          vigilare e garantire il corretto svolgimento delle prove. Pertanto, il
          trattamento dei dati audio e video si rende necessario, pena
          l’esclusione dalla prova. Le registrazioni saranno conservate con la
          massima cura per la durata strettamente necessaria e potranno essere
          utilizzate solo al fine di verificare a posteriori il corretto
          svolgimento dell’esame, a tutela dell’Ateneo e degli iscritti alla
          prova, anche in caso di contestazioni. In nessun caso le registrazioni
          saranno rese pubbliche, così come i documenti di identità
          eventualmente richiesti, i quali verranno successivamente eliminati in
          conformità alla normativa vigente.
        </li>
      </ul>
      <h5 className="text-uppercase font-weight-bold">
        Organizzazione preliminare dello studente
      </h5>
      L’utilizzo di Respondus richiede che lo studente utilizzi un computer con
      sistema operativo Windows o MacOS. Sono esclusi tablet, smartphone e
      computer dodati di sistema operativo Linux.
      <p>Prima della prova d’esame, gli studenti avranno cura di:</p>
      <ul>
        <li>
          assicurarsi che l’illuminazione dell’ambiente di lavoro sia adeguata a
          consentire una nitida visione di monitoraggio;
        </li>
        <li>
          evitare che, nel medesimo ambiente, siano presenti altre persone o
          monitor per tutta la durata della prova (si ricorda che l’intera prova
          sarà registrata);
        </li>
        <li>
          evitare che il computer si blocchi durante la prova, in particolare
          utilizzare il computer servendosi di alimentazione da rete elettrica e
          non da batterie che possono scaricarsi;
        </li>
        <li>predisporre la scrivania il più possibile sgombra;</li>
        <li>preparare un documento d’identità in corso di validità.</li>
      </ul>
      <h5 className="text-uppercase font-weight-bold">
        Modalità di svolgimento della prova d’esame
      </h5>
      <p>Durante lo svolgimento dell’esame lo studente:</p>
      <ul>
        <li>
          nell’orario fissato per l’esame si collega su Moodle mediante il link
          fornito dal docente:
          <ul>
            <li>
              {" "}
              installa innanzitutto il LockDown Browser, cliccando nel link
              &quot;Download LockDown Browser&quot; e seguendo le istruzioni di
              installazione;
            </li>
            <li>
              {" "}
              preme il pulsante &quot;Launch LockDown Browser&quot; per entrare
              nell’ambiente controllato ai fini dello svolgimento dell’esame;
            </li>
            <li>
              {" "}
              inizia a svolgere la prova d’esame premendo il pulsante
              &quot;Start Quiz&quot; e seguendo le istruzioni;
            </li>
          </ul>
        </li>
        <li>mantiene attivi il microfono e la webcam;</li>
        <li>
          mantiene il cellulare e qualsiasi altro strumento di comunicazione
          fuori dalla sua portata;
        </li>
        <li>garantisce l’assenza di altre persone nella stanza di lavoro;</li>
        <li>
          evita di provocare qualsiasi rumore, dal momento che la prova si
          svolge a microfoni attivi;
        </li>
        <li>
          attende in ogni caso il termine della prova senza muoversi dal proprio
          posto.
        </li>
      </ul>
      <p>
        In caso di caduta della connessione Internet, Respondus tenta di
        riconnettersi in modo automatico per consentire la prosecuzione della
        prova; in caso questo risulti impossibile, lo segnala allo studente, il
        quale contatta via chat il docente per concordare un’altra data di
        svolgimento oppure comunicare la propria volontà di ritiro dalla prova.
      </p>
      <p>
        In caso di interruzione del collegamento video o audio per un periodo
        consistente durante lo svolgimento della prova, il docente si riserva di
        considerare la possibile ripetizione dell’esame in forma orale.
      </p>
      <p>
        Se lo studente viene sorpreso a copiare o assume comportamenti di natura
        scorretta ovvero abbandona la postazione di lavoro prima della
        conclusione della prova, il suo esame potrà essere annullato, previa
        richiesta di delucidazioni all’interessato.
      </p>
      <p></p>
      <h5 className="text-uppercase font-weight-bold">
        Comunicazione con lo studente (OPZIONALE)
      </h5>
      Al fine di comunicare con gli studenti durante la prova o di visionare lo
      spazio di lavoro, il docente può opzionalmente richiedere che gli studenti
      siano collegati ad una riunione Teams (o altro sistema di videoconferenza
      predisposto dal docente) utilizzando lo smartphone o analogo dispositivo.
      <p></p>
      <h5 className="text-uppercase font-weight-bold">
        Consegna dell’elaborato
      </h5>
      <p>
        La consegna dell’elaborato avviene cliccando sul pulsante &quot;Invia
        tutto e termina&quot;. Coloro che, in assenza di giustificati e
        dimostrabili motivi, non rispettassero i tempi previsti sarebbero
        considerati come ritirati dalla prova.
      </p>
      <h5 className="text-uppercase font-weight-bold">
        Pubblicazione risultati, visione elaborati e verbalizzazione
      </h5>
      <p>
        Ove il docente maturi perplessità sulla genuinità dell’elaborato, può
        integrare la prova con domande orali. Nel caso si riscontrino
        irregolarità diffuse che mettano in dubbio la correttezza complessiva
        della prova, il docente si riserva di annullarla a carico di tutti gli
        studenti coinvolti.
      </p>
      <p>
        Una volta corretti gli elaborati e data la possibilità agli studenti di
        visionare il compito, qualora non siano previste prove successive, si
        annotano su ESSE3 gli esiti di voto secondo le modalità consuete.
      </p>
    </div>
  );

  const guidelines = [
    {
      id: "parte1",
      label: "PARTE 1 - INDICAZIONI GENERALI",
      link: `${match.path}/indicazioni-generali`,
      render: PartOne,
    },
    {
      id: "parte2",
      label: "PARTE 2 - ISTRUZIONI PER LE PROVE D’ESAME IN FORMA ORALE",
      link: `${match.path}/istruzioni-prove-esame-forma-orale`,
      render: PartTwo,
    },
    {
      id: "parte3",
      label: "PARTE 3 - ISTRUZIONI PER LE PROVE D’ESAME IN FORMA SCRITTA",
      link: `${match.path}/istruzioni-prove-esame-forma-scritta`,
      render: PartThree,
    },
    {
      id: "parte4",
      label: "PARTE 4 - ISTRUZIONI PER LE PROVE D’ESAME AL COMPUTER",
      link: `${match.path}/istruzioni-prove-esame-computer`,
      render: PartFour,
    },
  ];

  return (
    <>
      <h4 className="font-weight-bold"> ESAMI DI PROFITTO A DISTANZA </h4>
      <h4 className="text-uppercase font-weight-bold">
        Linee guida per i docenti e gli studenti
      </h4>
      <p>
        Nella presente guida sono fornite ai docenti e agli studenti indicazioni
        utili al corretto svolgimento degli esami di profitto a distanza, nel
        quadro di un’efficace verifica dei caratteri di correttezza e genuinità.
      </p>
      <Alert variant="danger">
        <span className="text-danger font-weight-bold">SUPPORTO ESAMI</span> I
        docenti che ne avessero necessità potranno richiedere supporto
        nell&apos;organizzazione delle prove d&apos;esame scrivendo al seguente
        indirizzo mail:{" "}
        <a href="supportoesamionline@unich.it">supportoesamionline@unich.it</a>
      </Alert>
      <InnerMenu menuItems={guidelines} hasChildren>
        {guidelines.map((guideline) => (
          <Route
            key={guideline.id}
            path={guideline.link}
            render={guideline.render}
          />
        ))}
      </InnerMenu>
      <p>
        Nella sezione <NavLink to="tutorial">Tutorial</NavLink> sono disponibili
        dei video e una guida per l&apos;utilizzo di Moodle e Respondus.
      </p>
    </>
  );
};

export default ExamsGuidelines;
