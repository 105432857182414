import LoginProps, { LoginFormData } from "./types";
import React, { useState } from "react";
import useLoginForm from "../../hooks/useLoginForm";
import useLogin from "../../hooks/useLogin";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import ToastErrorLogin from "../../components/ToastErrorLogin";

const Login: React.FunctionComponent<LoginProps> = () => {
  const [mutate, { isLoading }] = useLogin();
  const { register, handleSubmit, errors } = useLoginForm();
  const [showToast, setShowToast] = useState<boolean>(false);
  const history = useHistory();

  const onSubmit = (data: LoginFormData) => {
    mutate(
      {
        username: data.username,
        password: data.password,
      },
      {
        onSuccess: () => {
          history.push("/");
        },
        onError: () => {
          setShowToast(true);
        },
      }
    );
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <h3 className="form-group d-flex flex-column justify-content-center align-items-sm-center">
        Accedi
      </h3>
      <p> Questa pagina è riservata agli amministratori del sito.
      </p>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId="username">
          <Form.Control
            type="text"
            placeholder="Inserisci matricola"
            name="username"
            ref={register}
          />
          {errors.username && (
            <p className="text-danger">{errors.username.message}</p>
          )}
        </Form.Group>
        <Form.Group controlId="username">
          <Form.Control
            type="password"
            placeholder="Inserisci password"
            name="password"
            ref={register}
          />
          {errors.password && (
            <p className="text-danger">{errors.password.message}</p>
          )}
        </Form.Group>
        <div className="form-group d-flex flex-column justify-content-center align-items-sm-center">
          <button type="submit" className="w-25 btn bg-primary text-white">
            Entra
          </button>
        </div>
      </Form>
      <ToastErrorLogin showModal={showToast} setShowModal={setShowToast} />
    </>
  );
};

export default Login;
