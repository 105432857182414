import { Admin } from "./../components/adminsList/types";
import { useMutation, queryCache } from "react-query";
import authInstance from "../API/AuthInstance";

export default function useUpdateAdmin() {
  return useMutation<Admin, Admin>(
    async (admin: Admin) => {
      const { data } = await authInstance.put(`/user/${admin.username}`, admin);
      return data;
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries("getAdmin");
      },
    }
  );
}
