import React from "react";
import TeachersProps from "./types";

const Teachers: React.FunctionComponent<TeachersProps> = () => {
  return (
    <>
      <p>
        I team dei docenti sono pubblicati alla pagina{" "}
        <a href="https://zeus.unich.it/teledidattica19/#docenti">
          https://zeus.unich.it/teledidattica19/#docenti
        </a>
        .
      </p>
      {/* <TeachersList /> */}
    </>
  );
};

export default Teachers;
