import "./styles.css";

import { Col, Row } from "react-bootstrap";
import React, { useCallback, useEffect, useState, useContext } from "react";
import DidactictsRoutes from "../../routes/DidactictsRoutes";
import { BrowserRouter as Router } from "react-router-dom";
import Sidebar from "../sidebar";
import Topbar from "../topbar";
import { getLinksFor } from "../../links/getLinksForFeatures";
import { getRoutesFor } from "../../routes/getRoutesForFeatures";
import AuthenticationContext from "../authenticationProvider/context";

function App() {
  const { accessToken } = useContext(AuthenticationContext);

  const [isLgViewport, setIsLgViewport] = useState<boolean>(
    window.innerWidth > 991
  );
  const [isMediumViewport, setIsMediumViewport] = useState<boolean>(
    window.innerWidth < 768
  );
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);

  const handleOpenTopbarMenu = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  const updateWebSize = useCallback(() => {
    setIsLgViewport(window.innerWidth > 991);
  }, []);

  const updateMobileSize = useCallback(() => {
    setIsMediumViewport(window.innerWidth < 768);
  }, []);

  const handleBodyScroll = useCallback(() => {
    if (isMediumViewport && isOpenMenu) {
      document.body.classList.add("not-scrollable-body");
    } else {
      document.body.classList.remove("not-scrollable-body");
    }
  }, [isMediumViewport, isOpenMenu]);

  useEffect(() => {
    handleBodyScroll();
  }, [handleBodyScroll]);

  useEffect(() => {
    window.addEventListener("resize", updateWebSize);
    return () => window.removeEventListener("resize", updateWebSize);
  }, [updateWebSize]);

  useEffect(() => {
    window.addEventListener("resize", updateMobileSize);
    return () => window.removeEventListener("resize", updateMobileSize);
  }, [updateMobileSize]);

  return (
    <Router>
      <Topbar
        isLgViewport={isLgViewport}
        isMediumViewport={isMediumViewport}
        links={getLinksFor([accessToken ? "authenticated" : "guest"])}
        handleOpenTopbarMenu={handleOpenTopbarMenu}
        isOpen={isOpenMenu}
      />
      <div style={{ overflowX: "hidden" }}>
        <Row>
          <Col xl={2} lg={2} className="mr-lg-4">
            <Sidebar
              isLgViewport={isLgViewport}
              links={getLinksFor([accessToken ? "authenticated" : "guest"])}
            />
          </Col>
          <Col xl={8} lg={9} md={12} className="mr-xl-5 ml-lg-2 my-5">
            <DidactictsRoutes
              routes={getRoutesFor([accessToken ? "authenticated" : "guest"])}
            />
          </Col>
        </Row>
      </div>
    </Router>
  );
}

export default App;
