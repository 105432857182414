import axios from "axios";
import { useQuery } from "react-query";

const { REACT_APP_API } = process.env;

const getUnsignedStudent = async () => {
  const { data } = await axios.get(`${REACT_APP_API}/student/freshman`);
  return data;
};

export default function useUnsignedStudent() {
  return useQuery(["getUnsignedStudents"], getUnsignedStudent);
}
