import { Admin } from "./../components/adminsList/types";
import { useMutation, queryCache } from "react-query";
import authInstance from "../API/AuthInstance";

export default function useCreateAdmin() {
  return useMutation<Admin, Admin>(
    async (admin: Admin) => {
      const { data } = await authInstance.post(`/user/register`, admin);
      return data;
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries("getAdmin");
      },
    }
  );
}
