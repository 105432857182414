import { Card } from "react-bootstrap";
import AltreIniziativeProps from "./types";
import React from "react";

const AltreIniziative: React.FunctionComponent<AltreIniziativeProps> = () => {
  return (
    <>
      <h4>
         INIZIATIVE CULTURALI E SOCIALI PER GLI STUDENTI
      </h4>
      <Card className="px-4 py-4 mb-4">
        <div>
	  <a href="https://teams.microsoft.com/l/team/19%3abc6c002187d14f598abe0ab7413d9887%40thread.tacv2/conversations?groupId=73f7476a-289f-4679-be03-1a56aaec11eb&tenantId=41f8b7d0-9a21-415c-9c69-a67984f3d0de">Corso di Racconto Breve (n.31)</a>
        </div>
      </Card>
      <Card className="px-4 py-4 mb-4">
        <div>
	  <a href="https://teams.microsoft.com/l/team/19%3ac93572d8e2ae439db536e238f6745883%40thread.tacv2/conversations?groupId=2ee112a7-bc2c-4c45-9e20-d11175de0831&tenantId=41f8b7d0-9a21-415c-9c69-a67984f3d0de">The Wolf of Marketing (n.32)</a>
	         </div>
      </Card>
      <Card className="px-4 py-4 mb-4">
	<div>
          <a href="https://teams.microsoft.com/l/team/19%3a1713a9c965164c05a3bb1568ea4304c7%40thread.tacv2/conversations?groupId=cdca36cd-8b49-4a85-b800-f58609c82a54&tenantId=41f8b7d0-9a21-415c-9c69-a67984f3d0de">Laboratorio di Europrogettazione (n.35)</a>
          	 </div>
      </Card>
      <Card className="px-4 py-4 mb-4">
	<div>
          <a href="https://teams.microsoft.com/l/team/19%3aa0b34822d4644dcc99db221134562586%40thread.tacv2/conversations?groupId=899c5ed8-39f2-44af-9f53-8a32d7b5c0eb&tenantId=41f8b7d0-9a21-415c-9c69-a67984f3d0de">Corso di Primo Soccorso (n.36)</a>
          	 </div>
      </Card>
</>
  );
};

export default AltreIniziative;
