import { LessonsUpdateModalProps } from "./types";
import { Modal } from "react-bootstrap";
import LessonEditFinalForm from "../editLessonFinalForm/index";
import React from "react";

const LessonsUpdateModal: React.FunctionComponent<LessonsUpdateModalProps> = ({
  show,
  handleClose,
  selectedItem,
  successModal,
  showModal,
  setLoading,
  renderToast,
  id,
  idp,
}) => {
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modifica Lezione</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedItem && (
            <LessonEditFinalForm
              selectedItem={selectedItem}
              handleClose={handleClose}
              renderToast={renderToast}
              successModal={successModal}
              showModal={showModal}
              setLoading={setLoading}
              id={id}
              idp={idp}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LessonsUpdateModal;
