import LogoutProps from "./types";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AuthenticationContext from "../../components/authenticationProvider/context";

const Logout: React.FunctionComponent<LogoutProps> = () => {
  const { setAccessToken } = useContext(AuthenticationContext);
  const history = useHistory();

  useEffect(() => {
    setAccessToken("");
    history.push("/");
  }, [history, setAccessToken]);

  return <></>;
};

export default Logout;
