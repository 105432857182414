import { StudentModalProps } from "./types";
import { Modal } from "react-bootstrap";
import StudentEditForm from "../../components/editStudentForm/index";
import React from "react";

const StudentModal: React.FunctionComponent<StudentModalProps> = ({
  show,
  handleClose,
  student,
  successModal,
  showModal,
  loading,
  setLoading,
  renderToast,
}) => {
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modifica Studente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StudentEditForm
            student={student}
            unsigned={true}
            handleClose={handleClose}
            renderToast={renderToast}
            successModal={successModal}
            showModal={showModal}
            loading={loading}
            setLoading={setLoading}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StudentModal;
