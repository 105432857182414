import React from "react";
import { PosDetailsProps, PathOfStudy } from "./types";
import { useHistory } from "react-router-dom";

const PosDetails: React.FunctionComponent<PosDetailsProps> = ({ data }) => {
  const history = useHistory();

  const handleClick = (slug: string, slugOfStudies: string) => {
    history.push(`/dashboard/lessons-exams/${slug}/${slugOfStudies}`);
  };
  return (
    <>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Codice</th>
              <th scope="col">Descrizione</th>
            </tr>
          </thead>
          <tbody>
            {data?.pathOfStudies.map((lessex: PathOfStudy) => (
              <tr
                key={lessex.id}
                onClick={() => handleClick(data.slug, lessex.slug)}
                style={{ cursor: "pointer" }}
              >
                <td>{lessex.id}</td>
                <td>{lessex.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PosDetails;
