import { Lesson } from "./../components/editLessonFinalForm/types";
import { yupResolver } from "@hookform/resolvers";
import { useForm } from "react-hook-form";
/* const [mutate, result] = useUpdateFreshman() */
import * as yup from "yup";

const useLessonExamForm = (defaultValues: Lesson) => {
  const schema = yup.object().shape({
    code: yup.string().required("Campo obbligatorio"),
    courseOfStudyCode: yup.string().required("Campo obbligatorio"),
    pathOfStudyId: yup.string().required("Campo obbligatorio"),
    description: yup.string().required("Campo obbligatorio"),
    teamsLink: yup.string(),
  });

  return useForm<Lesson>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });
};

export default useLessonExamForm;
