import React, { useState } from "react";
import useLessonExam from "../../hooks/useLessonExam";
import Loader from "../loader/Loader";
import PosDetails from "../posDetails/index";
import { LessonExam, LessonsExamsListProps } from "./types";

const LessonsExamsList: React.FunctionComponent<LessonsExamsListProps> = () => {
  const { data, isSuccess, isLoading } = useLessonExam();
  const [selectedItemDetails, setSelectedItemDetails] = useState<LessonExam>();

  const handleClick = (lessex: LessonExam) => {
    window.scrollTo(0, 0);
    setSelectedItemDetails(lessex);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {selectedItemDetails && (
        <>
          <h3>Percorso</h3>
          <PosDetails data={selectedItemDetails} />
        </>
      )}
      {isSuccess && (
        <>
          <h3>Corso di studi</h3>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Codice</th>
                  <th scope="col">Descrizione</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((lessex: LessonExam) => (
                  <tr
                    key={lessex.code}
                    onClick={() => handleClick(lessex)}
                    style={{ cursor: "pointer" }}
                  >
                    <td>{lessex.code}</td>
                    <td>{lessex.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};

export default LessonsExamsList;
