import React, { useEffect } from "react";
import { UserFormProps, UserFormData } from "./types";
import { Form, Button } from "react-bootstrap";
import useCreateAdmin from "../../hooks/useCreateAdmin";
import useUserForm from "../../hooks/useUserForm";
import "./styles.css";
import Loader from "../loader/Loader";

const CreateUserForm: React.FunctionComponent<UserFormProps> = ({
  handleClose,
  renderToast,
  loading,
  setLoading,
}) => {
  const [mutate] = useCreateAdmin();
  const { register, handleSubmit, errors } = useUserForm({
    username: "",
    firstname: "",
    lastname: "",
    email: "",
    studentID: "",
    isEnabled: true,
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  const onSubmit = (data: UserFormData) => {
    mutate(
      {
        firstName: data.firstname,
        lastName: data.lastname,
        email: data.email,
        studentID: data.studentID,
        isEnabled: true,
        username: data.studentID,
      },
      {
        onSuccess: () => {
          renderToast(true, "upd");
        },
        onError: () => {
          renderToast(false, "upd");
        },
      }
    );
    handleClose();
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId="formBasicName">
          <Form.Label>Nome</Form.Label>
          <Form.Control
            type="text"
            placeholder="Inserisci nome"
            name="firstname"
            ref={register}
          />
          {errors.firstname && (
            <p className="text-danger">{errors.firstname.message}</p>
          )}
        </Form.Group>
        <Form.Group controlId="formBasicLastname">
          <Form.Label>Cognome</Form.Label>
          <Form.Control
            type="text"
            placeholder="Inserisci cognome"
            name="lastname"
            ref={register}
          />
          {errors.lastname && (
            <p className="text-danger">{errors.lastname.message}</p>
          )}
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            placeholder="Inserisci email"
            name="email"
            ref={register}
          />
          {errors.email && (
            <p className="text-danger">{errors.email.message}</p>
          )}
        </Form.Group>
        <Form.Group controlId="formBasicStudentId">
          <Form.Label>Matricola</Form.Label>
          <Form.Control
            type="text"
            placeholder="Inserisci matricola"
            name="studentID"
            ref={register}
          />
        </Form.Group>
        <div className="d-flex justify-content-end">
          <Button className="w-25 mr-2" type="submit">
            Crea
          </Button>
          <Button className="w-25" variant="secondary" onClick={handleClose}>
            Annulla
          </Button>
        </div>
      </Form>
    </>
  );
};

export default CreateUserForm;
