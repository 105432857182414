import { LoginFormData } from "./../screens/login/types";
import axios from "axios";
import { useMutation } from "react-query";
import { useContext } from "react";
import AuthenticationContext from "../components/authenticationProvider/context";

const { REACT_APP_API } = process.env;

export default function useLogin() {
  const { setAccessToken } = useContext(AuthenticationContext);
  return useMutation(
    async (dataLogin: LoginFormData) => {
      const { data } = await axios.post(
        `${REACT_APP_API}/user/login`,
        dataLogin
      );
      return data;
    },
    {
      onSuccess: (data) => {
        setAccessToken(data.token);
      },
    }
  );
}
