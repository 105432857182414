import { useQuery } from "react-query";
import authInstance from "../API/AuthInstance";

const getLessonExam = async () => {
  const { data } = await authInstance.get(`/lesson`);
  return data;
};

export default function useLessonExam() {
  return useQuery(["getLessonExam"], getLessonExam);
}
