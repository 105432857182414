import "./styles.css";

import { Col, Navbar, NavbarBrand, Row } from "react-bootstrap";

import { NavLink } from "react-router-dom";
import React from "react";
import TopbarProps from "./types";

const Topbar: React.FC<TopbarProps> = ({
  links,
  isLgViewport,
  isMediumViewport,
  handleOpenTopbarMenu,
  isOpen,
}) => {
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        className="topbar justify-content-between"
        sticky="top"
      >
        <div className="brand-container">
          <NavbarBrand>
            <img
              src="https://www.unich.it/sites/default/files/logouda_bordooro_bis.png"
              alt="logo"
              width={45}
              height={45}
              className="logo-container"
            />
          </NavbarBrand>
          <Navbar.Text className="text-white small">
            Università degli Studi <br /> &quot;G.d&apos;Annunzio&quot; <br />
            Chieti - Pescara
          </Navbar.Text>
        </div>
        <Navbar.Text className="topbar-title text-right">
          <span className="text-white">Teledidattica</span>
          <div className="topbar-subtitle">UdA</div>
        </Navbar.Text>
        {!isLgViewport ? (
          <Navbar.Toggle
            as="div"
            bsPrefix="burger"
            onClick={handleOpenTopbarMenu}
            aria-controls="responsive-navbar-nav"
          >
            <div
              className={
                isOpen ? "burger-lines rotate-top-burger-line" : "burger-lines"
              }
            />
            <div className={isOpen ? "burger-line hidden" : "burger-line"} />
            <div
              className={
                isOpen
                  ? "burger-lines rotate-bottom-burger-line"
                  : "burger-lines"
              }
            />
          </Navbar.Toggle>
        ) : null}
        {!isLgViewport ? (
          <Navbar.Collapse id="responsive-navbar-nav">
            <Row
              className={`collapsable-menu ${
                isMediumViewport ? "mobile-menu" : ""
              }`}
            >
              {links.map(({ to, title, exact }) => (
                <Navbar.Toggle
                  as={Col}
                  bsPrefix={`topbar-item-container ${
                    isMediumViewport ? "mobile-item-container" : ""
                  }`}
                  key={title}
                  md={4}
                  onClick={handleOpenTopbarMenu}
                  aria-controls="responsive-navbar-nav"
                >
                  <NavLink
                    className="topbar-item"
                    id={title}
                    exact={exact}
                    to={to}
                    aria-controls="responsive-navbar-nav"
                  >
                    <div style={{ textAlign: "center", width: "100%" }}>
                      {title}
                    </div>
                  </NavLink>
                </Navbar.Toggle>
              ))}
            </Row>
          </Navbar.Collapse>
        ) : null}
      </Navbar>
    </>
  );
};

export default Topbar;
