import { Container, ListGroupItem, Row } from "react-bootstrap";
import { NavLink, useRouteMatch } from "react-router-dom";
import React, { useCallback } from "react";

import { InnerSubMenuProps } from "./types";

const InnerSubMenu: React.FunctionComponent<InnerSubMenuProps> = ({
  subMenuItems,
  children,
  setCurrentPathOfStudyCode,
}) => {
  const match = useRouteMatch();

  const handleClick = useCallback(
    (pathOfStudyId) => () => {
      return setCurrentPathOfStudyCode
        ? setCurrentPathOfStudyCode(pathOfStudyId)
        : undefined;
    },
    [setCurrentPathOfStudyCode]
  );

  return (
    <Container className="sub-menu-item-container mb-3">
      {subMenuItems?.map((item) => (
        <Row key={item.id}>
          {match.path === "/lezioni-online" || match.path === "/esami" ? (
            <NavLink
              to={item.link}
              href={item.link}
              exact={item.exact}
              className="sub-menu-item py-2 pl-4"
              onClick={handleClick(item.id)}
            >
              {item.label}
            </NavLink>
          ) : (
            <ListGroupItem
              bsPrefix="sub-menu-item-link"
              className="py-2 pl-4"
              as="a"
              href={item.link}
            >
              {item.label}
            </ListGroupItem>
          )}
        </Row>
      ))}
      {children}
    </Container>
  );
};

export default InnerSubMenu;
