import React, { useMemo } from "react";

import { Alert } from "react-bootstrap";
import { Course } from "../../components/innerMenu/types";
import { CourseOfStudy } from "../../interfaces/courseOfStudyType";
import Loader from "../../components/loader/Loader";
import MenuPane from "../../components/menuPane/MenuPane";
import { dashify } from "../../utils";
import useExams from "../../hooks/useExams";
import { useRouteMatch } from "react-router-dom";

const OnlineLessons2020: React.FunctionComponent = () => {
  const match = useRouteMatch();
  const { data, isSuccess, isLoading } = useExams();

  const onlineLessons2020 = useMemo(() => {
    if (data !== undefined) {
      return data?.map((cos: CourseOfStudy) => {
        const pathOfStudies: Course[] = cos.pathOfStudies.map((path) => {
          return {
            id: path.id,
            label: `${path.description
              .substring(path.description.indexOf("percorso") + 9)
              .toUpperCase()}`,
            courseOfStudyCode: path.courseOfStudyCode,
            link: `${match.path}/${dashify(cos.description)}-${
              cos.code
            }/${dashify(path.description)}`,
            teamsLinks: cos.commonPath
              ? path.lessons
                  .concat(cos.commonPath.lessons)
                  .sort((l1, l2) =>
                    l1.description.localeCompare(l2.description)
                  )
              : path.lessons,
            subMenuItems: [],
          };
        });

        return {
          id: cos.code,
          label: `${cos.code} - ${cos.description.toUpperCase()}`,
          link: `${match.path}/${dashify(cos.description)}-${cos.code}`,
          subMenuItems: pathOfStudies,
          teamsLinks:
            pathOfStudies.length > 1 ? [] : pathOfStudies[0].teamsLinks,
        };
      });
    }
    return [];
  }, [data, match.path]);

  return (
    <>
      <p>
        In questa pagina trovate i team degli insegnamenti in teledidattica per
        il 2020/2021. Per entrare nel team è sufficiente cliccare nel nome
        dell'insegnamento (solo la prima volta, poi il team apparirà
        automaticamente accedendo a Teams). Le lezioni si svolgeranno secondo i{" "}
        <a href="https://www.unich.it/sua">calendari delle lezioni</a> in corso
        di pubblicazione.
      </p>
      <Alert variant="primary">
        <span className="text-danger font-weight-bold">ACCESSO AI TEAM</span>: i
        team saranno accessibili da questa pagina a partire da lunedì 5 ottobre
        2020.
      </Alert>
      {isLoading ? (
        <Loader />
      ) : (
        isSuccess && <MenuPane menuItems={onlineLessons2020} filter='lesson' />
      )}
    </>
  );
};

export default OnlineLessons2020;
