import { Alert } from "react-bootstrap";
import DegreeExamsProps from "./types";
import React from "react";

const DegreeExams: React.FunctionComponent<DegreeExamsProps> = () => {
  return (
    <>
      <Alert variant="primary">
        La lista delle prossime sedute di laurea è disponibile nella pagina
        delle{" "}
        <a href="https://www.unich.it/sedute_online">
          sedute di laurea on-line
        </a>
        . Da qui possono partecipare gli studenti e i loro ospiti (parenti e
        amici).
      </Alert>
      <Alert variant="dark">
        <span className="text-danger">
          Per i <strong>DOCENTI</strong> nelle commissioni di laurea
        </span>
        : è disponibile il manuale operativo per la{" "}
        <a href="https://zeus.unich.it/teledidattica19/doc/Commissioni_di_Laurea.pdf">
          verbalizzazione online degli appelli di laurea
        </a>
        .
      </Alert>
      <p>
        Gli{" "}
        <span className="font-weight-bold">
          esami di laurea saranno gestiti dalle segreterie didattiche
        </span>{" "}
        dei singoli dipartimenti o scuole, eventualmente in collaborazione con i{" "}
        <span className="font-weight-bold">presidenti e/o segretari</span> delle
        sedute di laurea. Essi provvederanno a:
      </p>
      <br />
      <ul>
        <li>
          creare i team (uno per ogni seduta di laurea) e pianificare le
          corrispondenti riunioni, secondo le istruzioni nella{" "}
          <a href="https://zeus.unich.it/teledidattica19/doc/Creazione-Team-Seduta_di_Laurea_v04.pdf">
            Guida per la creazione delle sedute di laurea
          </a>
          .
        </li>
        <li>
          pubblicizzare i link alle riunioni, inviandoli a{" "}
          <a href="mailto:simone.calci@unich.it">Simone Calci</a> per la
          pubblicazione sulla pagina delle{" "}
          <a href="https://www.unich.it/sedute_online">
            sedute di laurea on-line
          </a>
          ; ovviamente è possibile pubblicizzare il link anche con altri canali,
          ad esempio sul sito del dipartimento o del corso di laurea.
        </li>
        <li>
          {" "}
          creare su ESSE3 gli appelli per la prova di laurea, come da
          indicazioni che dovrebbero essere giunte dal dott. Masotti.
        </li>
      </ul>
      Gli <span className="font-weight-bold">studenti</span> e i{" "}
      <span className="font-weight-bold">docenti</span> potranno accedere alle
      sedute di laurea un due modi: se sono stati aggiunti al team della seduta,
      troveranno la riunione già pianificata su Microsoft Teams e potranno
      partecipare con un click; altrimenti, potranno comunque partecipare
      tramite il link alla riunione, disponibile nella pagina delle{" "}
      <a href="https://www.unich.it/sedute_online">sedute di laurea on-line</a>{" "}
      o ricevuto per altri canali. Si consulti a proposito la{" "}
      <a href="https://zeus.unich.it/teledidattica19/doc/Partecipazione_alla_Seduta_di_Laurea_v01.pdf">
        Guida per la partecipazione alle sedute di laurea.
      </a>
      <p>
        Per eventuale supporto nella creazione del team e della riunione
        pubblica per le sedute di laurea, è possibile contattare{" "}
        <a href="mailto:sedutedilaureaadistanza@unich.it">
          sedutedilaureaadistanza@unich.it
        </a>
        . Per altri tipi di informazioni contattare il prof. Stefano Za
        all&apos;indirizzo{" "}
        <a href="mailto:stefano.za@unich.it">stefano.za@unich.it</a>.
      </p>
    </>
  );
};

export default DegreeExams;
