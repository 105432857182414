import { Card } from "react-bootstrap";
import PlacementProps from "./types";
import React from "react";

const Placement: React.FunctionComponent<PlacementProps> = () => {
  return (
    <>
      <p>
        Quelli che seguono sono i team gestiti dal settore{" "}
        <a href="https://orientamento.unich.it/">Orientamento & Placement</a>{" "}
        per le proprie attività:
      </p>
      <Card className="px-4 py-4 mb-4">
        <div>
          <a href="https://teams.microsoft.com/l/team/19:7d8944fcabe54ee3b678883d7d4a4d13%40thread.tacv2/conversations?groupId=a8177ad3-bee9-490e-8e3e-deb2298ec3af&tenantId=41f8b7d0-9a21-415c-9c69-a67984f3d0de">
            Consulenze del Servizio Placement, di primo orientamento in uscita
            dai CdS, al Lavoro e per la creazione di impresa
          </a>
          : team utilizzato per la gestione degli incontri di primo orientamento
          in uscita dai CdS ed al Lavoro. Le riunioni possono essere prenotate
          dai beneficiari secondo quanto riportato sulla pagina &quot;
          <a href="https://orientamento.unich.it/avvisi/line-incontri-di-placement-studenti-e-laureati">
            On line, incontri di Placement per studenti e laureati
          </a>
          &quot;.
        </div>
      </Card>
      <Card className="px-4 py-4 mb-4">
        <div>
          <a href="https://teams.microsoft.com/l/team/19:2fd350b364884f21ae2605df7d546f93%40thread.tacv2/conversations?groupId=275d06b1-e888-47e3-ad4d-a26dd4aafe73&tenantId=41f8b7d0-9a21-415c-9c69-a67984f3d0de">
            CAPM
          </a>
          : team utilizzato per la gestione del corso Project Management
          attivato nell’ambito della collaborazione avviata tra l’Ateneo ed il
          Project Management Institute.
        </div>
      </Card>
      <Card className="px-4 py-4">
        <div>
          <a href="https://teams.microsoft.com/l/team/19:50612745fab74500aec288e2f7b531e6%40thread.tacv2/conversations?groupId=33069056-4fba-4752-b97e-6bf7d6250962&tenantId=41f8b7d0-9a21-415c-9c69-a67984f3d0de">
            #TalentTalk
          </a>
          : team utilizzato per la gestione dell’omonimo format on line
          progettato a beneficio della Utenza del Servizio, appunto, lato
          Domanda di Lavoro (studenti, laureati e dottorandi di Ricerca).
        </div>
      </Card>
    </>
  );
};

export default Placement;
