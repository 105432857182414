import axios from "axios";
import { Student } from "./../components/studentsList/types";
import { useMutation, queryCache } from "react-query";

const { REACT_APP_API } = process.env;

export default function useDeleteStudent() {
  return useMutation<Student, string>(
    async (id: string) => {
      const { data } = await axios.delete(`${REACT_APP_API}/student/${id}`);
      return data;
    },
    {
      onSettled: () => {
        queryCache.removeQueries("getUnsignedStudents");
      },
    }
  );
}
