import { useQuery } from "react-query";
import authInstance from "../API/AuthInstance";

const getTeacher = async () => {
  const { data } = await authInstance.get(`/teacher`);
  return data;
};

export default function useTeacher() {
  return useQuery(["getTeacher"], getTeacher);
}
