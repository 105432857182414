import { UserModalProps } from "./types";
import { Modal } from "react-bootstrap";
import UserEditForm from "../editUserForm/index";
import React from "react";

const UserModal: React.FunctionComponent<UserModalProps> = ({
  show,
  handleClose,
  admin,
  successModal,
  showModal,
  loading,
  setLoading,
  renderToast,
}) => {
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modifica Utente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UserEditForm
            admin={admin}
            handleClose={handleClose}
            renderToast={renderToast}
            successModal={successModal}
            showModal={showModal}
            loading={loading}
            setLoading={setLoading}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserModal;
