import React, { useState } from "react";
import { FinalLessonListProps, Lesson } from "./types";
import LessonsUpdateModal from "../lessonsUpdateModal/LessonsUpdateModal";
import LessonsCreateModal from "../createLessonModal/LessonsCreateModal";
import useDashboarLesson from "../../hooks/useDashboarLesson";
import { useParams } from "react-router-dom";
import ToastBar from "../ToastBar/index";
import Loader from "../loader/Loader";
import { Button } from "react-bootstrap";
import "./styles.css";

const FinalLessonList: React.FunctionComponent<FinalLessonListProps> = () => {
  const { id, idp } = useParams<{ id: string; idp: string }>();
  const { data, isSuccess, isLoading } = useDashboarLesson(id, idp);
  const [selectedItem, setSelectedItem] = useState<Lesson>();
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>("");
  const [showCreate, setShowCreate] = useState<boolean>(false);

  const handleCloseCreate = () => setShowCreate(false);

  const handleCreate = () => {
    setShowCreate(true);
  };

  const renderToast = (result: boolean, type: string) => {
    setShowModal(true);
    setSuccessModal(result);
    setModalType(type);
  };

  const handleUpdate = (lessex: Lesson) => {
    setSelectedItem(lessex);
    setShowUpdateModal(true);
  };

  const handleCloseUpdateModal = () => setShowUpdateModal(false);

  if (isLoading) {
    return <Loader />;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <h1 className="text-uppercase">{idp}</h1>
      <ToastBar
        result={successModal}
        setShowModal={setShowModal}
        showModal={showModal}
        type={modalType}
      />
      {isSuccess && (
        <>
          <div className="d-flex flex-row-reverse">
            <Button
              variant="primary"
              className="mb-4 d-flex flex-row-reverse"
              onClick={() => handleCreate()}
            >
              Aggiungi
            </Button>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Codice corso</th>
                  <th scope="col">Codice percorso</th>
                  <th scope="col">Codice lezione</th>
                  <th scope="col">Descrizione</th>
                  <th scope="col">Link di Teams</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((lessex: Lesson) => (
                  <tr
                    key={lessex.code}
                    onClick={() => handleUpdate(lessex)}
                    style={{ cursor: "pointer" }}
                  >
                    <td>{lessex.courseOfStudyCode}</td>
                    <td>{lessex.pathOfStudyId}</td>
                    <td>{lessex.code}</td>
                    <td>{lessex.description}</td>
                    <td>{lessex.teamsLink}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}

      <LessonsCreateModal
        cos={data.length ? data[0].courseOfStudyCode : ""}
        pos={data.length ? data[0].pathOfStudyId : ""}
        show={showCreate}
        handleClose={handleCloseCreate}
        successModal={successModal}
        renderToast={renderToast}
        setLoading={setLoading}
      />

      {selectedItem && (
        <LessonsUpdateModal
          show={showUpdateModal}
          handleClose={handleCloseUpdateModal}
          selectedItem={selectedItem}
          showModal={showModal}
          successModal={successModal}
          renderToast={renderToast}
          setLoading={setLoading}
          id={id}
          idp={idp}
        />
      )}
    </>
  );
};

export default FinalLessonList;
