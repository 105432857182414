import axios from "axios";
import { useQuery } from "react-query";

const { REACT_APP_API } = process.env;

const getExamsBySlug = async () => {
  const { data } = await axios.get(`${REACT_APP_API}/lesson`);
  return data;
};

export default function useExams() {
  return useQuery(["courseOfStudySlug"], getExamsBySlug);
}
