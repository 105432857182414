import { Alert } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import React from "react";
import TutorialProps from "./types";

const Tutorial: React.FunctionComponent<TutorialProps> = () => {
  return (
    <>
      <h5 className="text-uppercase font-weight-bold">
        Tutorial sull&apos;utilizzo di Moodle e Respondus
      </h5>
      <h6 className="text-uppercase font-weight-bold">
        Video tutorial per gli studenti
      </h6>
      <ol>
        <li>
          <a href="https://www.youtube.com/watch?v=H4bDkGl20KM&feature=youtu.be">
            Come installare Respondus
          </a>
          : Istruzioni per gli studenti su come installare Respondus per
          sostenere un esame.
        </li>
      </ol>
      <h6 className="text-uppercase font-weight-bold">
        Video tutorial per i docenti
      </h6>
      <ol>
        <li>
          <a href="https://www.youtube.com/watch?v=6dOb23PaI2o&feature=youtu.be">
            Come accedere al sito Moodle elearning.unich.it
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=F5QrXbE3s4o&feature=youtu.be">
            Come creare un Quiz su Moodle
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=M2j04HIA-G4&feature=youtu.be">
            Come creare un deposito di domande
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=agKEKKHHIM0&feature=youtu.be">
            Come inserire le domande d&apos;esame nel quiz
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=b67_Aw4VPVM">
            Come attivare il software di proctoring Respondus
          </a>
        </li>
      </ol>
      <h6 className="text-uppercase font-weight-bold">
        Tutorial per i docenti (pdf)
      </h6>
      <ol>
        <li>
          <a href="https://zeus.unich.it/teledidattica19/doc/tutorial_Moodle.pdf">
            Tutorial Moodle e Respondus
          </a>{" "}
          (pdf)
        </li>
      </ol>
      <h5 className="text-uppercase font-weight-bold">
        Video tutorial sull&apos;utilizzo di Microsoft Teams
      </h5>
      <ol>
        <li>
          <a href="https://www.youtube.com/watch?v=t_Y6ijDjGwg&feature=youtu.be">
            Pianificare una lezione
          </a>
          : Come pianificare una lezione e caricare le slides.
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=AfJOEnxVZtw&feature=youtu.be">
            Lezione con le slides
          </a>
          : Come fare una lezione utilizzando le slides.
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=rGK85nsUGoU&feature=youtu.be">
            Lezione con condivisione
          </a>
          : Come fare una lezione condividendo applicazioni e desktop.
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=5VT-5nL6Eac&feature=youtu.be">
            Programmare un evento live
          </a>
          : Come programmare una lezione con più di 250 studenti.
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=T3Eu6NJUeGI&feature=youtu.be">
            Lezione in modalità evento live
          </a>
          : Come fare una lezione con più di 250 studenti.
        </li>
      </ol>

      <h5 className="text-uppercase font-weight-bold">
        Brevi istruzioni per svolgere una lezione online
      </h5>
      <p>
        Per avviare una lezione ci sono due metodi. Il più immediato è di
        cliccare nell&apos;icona che trovate in basso al centro &quot;
        <span className="font-italic">Riunione immediata</span>&quot; e questo
        vi permetterà di avviare una lezione. Una volta avviata, è possibile
        svolgere le seguenti azioni:
      </p>
      <ul>
        <li>
          <span className="font-italic">
            mostrare delle slide in Power Point
          </span>
          : cliccare sul pulsante
          <span className="font-italic"> Condividi</span> in basso al centro,
          poi
          <span className="font-italic"> Sfoglia</span> e quindi
          <span className="font-italic"> Carica dal computer locale</span>.
          Questo va fatto solo la prima volta, poi le slide caricate rimangono
          disponibili nell&apos;area <span className="font-italic">File </span>
          del team. Questo metodo funziona solo con slide in Power Point, e non
          per documenti PDF, Word o altro. Nel caso il vostro materiale
          didattico non sia in Power Point, potete condividere lo schermo del
          vostro PC (vedere qui sotto) e utilizzare un qualunque programma
          vogliate per mostrare il materiale agli studenti.
        </li>
        <li>
          <span className="font-italic">
            mostrare agli studenti lo schermo del proprio computer, nel quale
            potete avviare qualunque applicazione
          </span>
          : cliccare sul pulsante{" "}
          <span className="font-italic">Condividi </span>
          in basso al centro e poi
          <span className="font-italic"> Condivisione schermo</span>. Per uscire
          dalla condivisione, ricliccare sul pulsante di condivisione.
        </li>
        <li>
          <span className="font-italic">
            vedere tutti i partecipanti e metterli in modalità silenziosa
          </span>
          : cliccare sul pulsante
          <span className="font-italic"> Mostra partecipanti</span>, in basso.
          Si aprirà una finestra a destra con l&apos;elenco attuale dei
          partecipanti. Cliccando su
          <span className="font-italic">
            {" "}
            Disattiva l&apos;audio di tutti
          </span>{" "}
          i partecipanti non potranno parlare ma solo scrivere nella chat.
          Questo è <span className="font-weight-bold">importante</span> perché è
          possibile che qualche studente lasci il microfono acceso per errore,
          disturbando quindi la lezione. Notare che lo studente può riattivare
          spontaneamente il microfono, cosa che è consigliata solo per fare
          delle domande.
        </li>
        <li>
          <span className="font-italic">scrivere alla chat della lezione</span>:
          cliccare sul pulsante
          <span className="font-italic"> Mostra conversazione</span> in basso al
          centro. Si aprirà una finestra a destra con la Chat della riunione
          nella quale tutti i partecipanti possono leggere e scrivere.
        </li>
        <li>
          <span className="font-italic">registrare la lezione</span>: cliccare
          l&apos;icona in basso al centro raffigurante tre puntini (Altre
          azioni) e selezionare &quot;
          <span className="font-italic">Avvia registrazione</span>&quot;. Al
          termine della lezione occorrerà terminare la registrazione con lo
          stesso pulsante. Il video della registrazione sarà immediatamente
          disponibile online per gli studenti nel sito{" "}
          <a href="http://web.microsoftstream.com">web.microsoftstream.com</a>.
        </li>
      </ul>
      <Alert variant="primary">
        IMPORTANTE: all&apos;inizio della lezione ricordarsi di avviare la
        registrazione seguendo le istruzioni qui sopra.
      </Alert>
      <p>
        Consulta anche le <NavLink to="faq">FAQ</NavLink> per ulteriori
        informazioni!
      </p>
    </>
  );
};

export default Tutorial;
