import ContactsProps from "./types";
import React from "react";

const Contacts: React.FunctionComponent<ContactsProps> = () => {
  return (
    <>
      <p>
        Il sito <a href="https://teledidattica.unich.it/">teledidattica.unich.it</a> è 
	gestito dai docenti:
     </p>
      <ul>
        <li>prof.ssa Francesca Scozzari - Delegata ai Sistemi informatici per la didattica</li>
        <li>prof. Gianluca Amato - Responsabile accordo CASA-EES e amministratore servizi cloud Microsoft</li>
      </ul>
      <p>
        Il gruppo di lavoro tecnico per la teledidattica comprende inoltre i tecnici Biblos, il
        servizio NOC dell&apos;ateneo, i rappresentanti degli studenti e i
        seguenti docenti dell&apos;ateneo:
      </p>
      <ul>
        <li>Fabio Fioravanti</li>
	<li>Maria Chiara Meo</li>
	<li>Luca Moscardelli</li>
        <li>Stefano Za</li>
      </ul>
      <p>
        Per qualunque problematica relativa alla didattica a distanza, si invita
        a contattare l&apos;indirizzo email appositamente predisposto{" "}
        <a href="mailto:didatticaadistanza@unich.it">didatticaadistanza@unich.it</a>
      </p>
    </>
  );
};

export default Contacts;
