import { LessonsCreateModalProps } from "./types";
import { Modal } from "react-bootstrap";
import LessonCreateFinalForm from "../createLessonFinalForm/index";
import React from "react";

const LessonsCreateModal: React.FunctionComponent<LessonsCreateModalProps> = ({
  show,
  handleClose,
  successModal,
  setLoading,
  renderToast,
  cos,
  pos,
}) => {
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modifica Lezione</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LessonCreateFinalForm
            cos={cos}
            pos={pos}
            handleClose={handleClose}
            renderToast={renderToast}
            successModal={successModal}
            setLoading={setLoading}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LessonsCreateModal;
