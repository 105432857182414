import { Context } from "../components/contextList/types";
import { useMutation, queryCache } from "react-query";
import authInstance from "../API/AuthInstance";

const { REACT_APP_API } = process.env;

export default function useUpdateContext() {
  return useMutation<Context, Context>(
    async (context: Context) => {
      const { data } = await authInstance.put(
        `${REACT_APP_API}/team-channel/${context.id}`,
        context
      );
      return data;
    },
    {
      onSuccess: (data, context) => {
        queryCache.invalidateQueries(context.category);
      },
    }
  );
}
