import { UserFormData } from "../components/editUserForm/types";
import { yupResolver } from "@hookform/resolvers";
import { useForm } from "react-hook-form";
/* const [mutate, result] = useUpdateFreshman() */
import * as yup from "yup";

const useUserForm = (defaultValues: UserFormData) => {
  const schema = yup.object().shape({
    firstname: yup.string().required("Campo obbligatorio"),
    lastname: yup.string().required("Campo obbligatorio"),
    email: yup
      .string()
      .email("Il campo deve essere una email")
      .required("Campo obbligatorio"),
    studentID: yup.string().required("Campo obbligatorio"),
  });

  return useForm<UserFormData>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });
};

export default useUserForm;
