import React, { useState } from "react";
import useUnsignedStudent from "../../hooks/useUnsignedStudent";
import Loader from "../loader/Loader";
import StudentModal from "../studentModal/StudentModal";
import { Student, StudentsListProps } from "./types";

const StudentsList: React.FunctionComponent<StudentsListProps> = ({
  showModal,
  successModal,
  renderToast,
}) => {
  const { data, isSuccess, isLoading } = useUnsignedStudent();
  const [loading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [student, setStudent] = useState<Student>();

  const handleShow = (currentstudent: Student) => {
    setStudent(currentstudent);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        isSuccess && (
          <>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Cognome</th>
                    <th scope="col">Email</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((student: Student) => (
                    <tr
                      key={student.code}
                      onClick={() => handleShow(student)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{student.firstName}</td>
                      <td>{student.lastName}</td>
                      <td>{student.email}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )
      )}
      {student && (
        <StudentModal
          show={show}
          handleClose={handleClose}
          student={student}
          showModal={showModal}
          successModal={successModal}
          renderToast={renderToast}
          loading={loading}
          setLoading={setLoading}
        />
      )}
    </>
  );
};

export default StudentsList;
