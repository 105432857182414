import React, { useEffect, useState } from "react";
import { LessonEditFinalFormProps, Lesson } from "./types";
import { Form, Button } from "react-bootstrap";
import useUpdateLessonFinal from "../../hooks/useUpdateLessonFinal";
import useDeleteLessonFinal from "../../hooks/useDeleteLessonFinal";
import useLessonFinalForm from "../../hooks/useLessonFinalForm";
import ConfirmDeleteModal from "../confirmDeleteModal";
import "./styles.css";

const LessonEditFinalForm: React.FunctionComponent<LessonEditFinalFormProps> = ({
  selectedItem,
  handleClose,
  renderToast,
  setLoading,
}) => {
  const [readOnly, setReadOnly] = useState<boolean>(true);
  const [mutate, { isLoading: isLoadingUpdate }] = useUpdateLessonFinal();
  const [mutateDel, { isLoading }] = useDeleteLessonFinal();
  const [confirmDelShow, setConfirmDelShow] = useState<boolean>(false);
  const [, setConfirmDel] = useState<boolean>(false);
  const { register, handleSubmit, errors } = useLessonFinalForm({
    code: selectedItem.code,
    courseOfStudyCode: selectedItem.courseOfStudyCode,
    pathOfStudyId: selectedItem.pathOfStudyId,
    description: selectedItem.description,
    teamsLink: selectedItem.teamsLink,
  });

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  useEffect(() => {
    setLoading(isLoadingUpdate);
  }, [isLoadingUpdate, setLoading]);

  const handleReadOnly = (e: any) => {
    e.preventDefault();
    setReadOnly(false);
  };

  const handleDeleteLesson = () => {
    setConfirmDelShow(true);
  };

  const confirmedDelete = () => {
    mutateDel(selectedItem, {
      onSuccess: () => {
        renderToast(true, "del");
        setLoading(false);
      },
      onError: () => {
        renderToast(false, "del");
        setLoading(false);
      },
    });
    handleClose();
  };

  const onSubmit = (data: Lesson) => {
    mutate(
      {
        ...selectedItem,
        courseOfStudyCode: data.courseOfStudyCode,
        pathOfStudyId: data.pathOfStudyId,
        description: data.description,
        teamsLink: data.teamsLink,
      },
      {
        onSuccess: () => {
          renderToast(true, "upd");
          setLoading(false);
          handleClose();
        },
        onError: () => {
          renderToast(false, "upd");
          setLoading(false);
          handleClose();
        },
      }
    );
  };

  const handleCloseDelModal = () => {
    setConfirmDelShow(false);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId="formBasicStudentId">
          <Form.Label>Codice corso</Form.Label>
          <Form.Control
            readOnly={true}
            type="text"
            placeholder="Inserisci il codice del corso"
            name="courseOfStudyCode"
            ref={register}
          />
          {errors.courseOfStudyCode && (
            <p className="text-danger">{errors.courseOfStudyCode.message}</p>
          )}
          <Form.Label>Codice Percorso</Form.Label>
          <Form.Control
            readOnly={true}
            type="text"
            placeholder="Inserisci il codice del percorso"
            name="pathOfStudyId"
            ref={register}
          />
          {errors.pathOfStudyId && (
            <p className="text-danger">{errors.pathOfStudyId.message}</p>
          )}
          <Form.Label>Codice Lezione</Form.Label>
          <Form.Control
            readOnly={true}
            type="text"
            placeholder="Inserisci il codice della lezione"
            name="code"
            ref={register}
          />
          {errors.code && <p className="text-danger">{errors.code.message}</p>}
          <Form.Label>Descrizione</Form.Label>
          <Form.Control
            readOnly={readOnly}
            type="text"
            placeholder="Inserisci la descrizione"
            name="description"
            ref={register}
          />
          {errors.description && (
            <p className="text-danger">{errors.description.message}</p>
          )}
          <Form.Label>Link di Teams</Form.Label>
          <Form.Control
            readOnly={readOnly}
            type="text"
            placeholder="Inserisci il link a teams"
            name="teamsLink"
            ref={register}
          />
          {errors.teamsLink && (
            <p className="text-danger">{errors.teamsLink.message}</p>
          )}
        </Form.Group>
        {confirmDelShow ? (
          <ConfirmDeleteModal
            show={confirmDelShow}
            handleClose={handleCloseDelModal}
            confirm={setConfirmDel}
            confirmedDelete={confirmedDelete}
          />
        ) : (
          <div className="d-flex justify-content-end">
            {readOnly ? (
              <Button className="w-25 mr-2" onClick={handleReadOnly}>
                Modifica
              </Button>
            ) : (
              <Button className="w-25 mr-2" type="submit">
                Salva
              </Button>
            )}
            <Button
              className="w-25"
              variant="secondary"
              onClick={handleDeleteLesson}
            >
              Elimina
            </Button>
          </div>
        )}
      </Form>
    </>
  );
};

export default LessonEditFinalForm;
