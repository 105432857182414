import React, { useEffect } from "react";
import UpdateContextModalProps, { ContextFormData } from "./types";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import useContextForm from "../../hooks/useContextForm";
import useUpdateContext from "../../hooks/useUpdateContext";
import Loader from "../loader/Loader";
import { useFieldArray } from "react-hook-form";

const UpdateContextForm: React.FunctionComponent<UpdateContextModalProps> = ({
  choosenOpt,
  handleClose,
  renderToast,
  selectedId,
  editItem,
  loading,
  setLoading,
}) => {
  const [mutate, { isLoading }] = useUpdateContext();
  const { register, handleSubmit, control, errors } = useContextForm({
    groupName: choosenOpt,
    category: choosenOpt,
    name: editItem.name,
    teamsLink: editItem.teamsLink,
    ownerEmail: editItem.ownerEmail,
    invitations: editItem.invitations,
  });
  const { fields, append } = useFieldArray({
    control,
    name: "invitations",
  });

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  const onSubmit = (data: ContextFormData) => {
    mutate(
      {
        ...editItem,
        id: selectedId,
        groupName: choosenOpt,
        category: choosenOpt,
        name: data.name,
        teamsLink: data.teamsLink,
        ownerEmail: data.ownerEmail,
        invitations: data.invitations?.map((inv: any) => inv.email) || [],
      },
      {
        onSuccess: () => {
          renderToast(true, "upd");
          setLoading(false);
        },
        onError: () => {
          renderToast(false, "upd");
          setLoading(false);
        },
      }
    );
    handleClose();
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId="formUpdateName">
          <Form.Label>Nome</Form.Label>
          <Form.Control
            type="text"
            placeholder="Inserisci nome"
            name="name"
            ref={register}
          />
          {errors.name && <p className="text-danger">{errors.name.message}</p>}
        </Form.Group>
        <Form.Group controlId="formUpdateLink">
          <Form.Label>Link</Form.Label>
          <Form.Control
            readOnly={true}
            type="text"
            placeholder="Inserisci link"
            name="teamsLink"
            ref={register}
          />
          {errors.teamsLink && (
            <p className="text-danger">{errors.teamsLink.message}</p>
          )}
        </Form.Group>
        <Form.Group controlId="formBasicLastname">
          <Form.Label>Amministratore Teams</Form.Label>
          <Form.Control
            type="email"
            placeholder="Inserisci email amministratore"
            name="ownerEmail"
            ref={register}
          />
          {errors.ownerEmail && (
            <p className="text-danger">{errors.ownerEmail.message}</p>
          )}
        </Form.Group>
        <Form.Group controlId="formBasicLastname">
          <Form.Label>Invitati Teams</Form.Label>
          <Container>
            {fields?.map((item, index) => (
              <Row key={item.id} className="mb-1">
                <Col sm={8}>
                  <Form.Control
                    type="email"
                    readOnly={item.email ? true : false}
                    placeholder="Inserisci email invitati"
                    name={`invitations[${index}].email`}
                    ref={register()}
                    defaultValue={item.email}
                  />
                </Col>
              </Row>
            ))}
          </Container>
          <Button
            type="button"
            onClick={() => append({ email: "" })}
            className="mt-2"
          >
            Aggiungi
          </Button>
        </Form.Group>
        <div className="d-flex justify-content-end">
          <Button className="w-25 mr-2" type="submit">
            Conferma
          </Button>
          <Button className="w-25" variant="secondary" onClick={handleClose}>
            Annulla
          </Button>
        </div>
      </Form>
    </>
  );
};

export default UpdateContextForm;
