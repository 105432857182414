import React, { useMemo } from "react";

import { Alert } from "react-bootstrap";
import { Course } from "../../components/innerMenu/types";
import { CourseOfStudy } from "../../interfaces/courseOfStudyType";
import Exams2020Props from "./types";
import Loader from "../../components/loader/Loader";
import MenuPane from "../../components/menuPane/MenuPane";
import { NavLink } from "react-router-dom";
import { dashify } from "../../utils";
import useExams from "../../hooks/useExams";
import { useRouteMatch } from "react-router-dom";

const Exams2020: React.FunctionComponent<Exams2020Props> = () => {
  const match = useRouteMatch();
  const { data, isSuccess, isLoading } = useExams();

  const exams2020 = useMemo(() => {
    if (data !== undefined) {
      return data?.map((cos: CourseOfStudy) => {
        const pathOfStudies: Course[] = cos.pathOfStudies.map((path) => {
          return {
            id: path.id,
            label: `${path.description
              .substring(path.description.indexOf("percorso") + 9)
              .toUpperCase()}`,
            courseOfStudyCode: path.courseOfStudyCode,
            link: `${match.path}/${dashify(cos.description)}-${
              cos.code
            }/${dashify(path.description)}`,
            teamsLinks: cos.commonPath
              ? path.lessons
                  .concat(cos.commonPath.lessons)
                  .sort((l1, l2) =>
                    l1.description.localeCompare(l2.description)
                  )
              : path.lessons,
            subMenuItems: [],
          };
        });

        return {
          id: cos.code,
          label: `${cos.code} - ${cos.description.toUpperCase()}`,
          link: `${match.path}/${dashify(cos.description)}-${cos.code}`,
          subMenuItems: pathOfStudies,
          teamsLinks:
            pathOfStudies.length > 1 ? [] : pathOfStudies[0].teamsLinks,
        };
      });
    }
    return [];
  }, [data, match.path]);

  return (
    <>
      <Alert variant="primary">
        Questi sono i team per svolgere gli esami per l'a.a. 2020/2021. È possibile
        accedere ai team degli esami 2019/2020 nel sito{" "}
        <a href="https://zeus.unich.it/teledidattica19/#esami">
          zeus.unich.it/teledidattica19/#esami
        </a>
        .
      </Alert>
      <p>
        Gli esami di profitto scritti e orali saranno svolti in modalità on-line,
        applicando i protocolli operativi già attualmente osservati.
      </p>
      <p>
        Gli studenti e le studentesse con disabilità e DSA potranno concordare
        eventuali modalità di esame personalizzate solo contattando
        preventivamente il servizio di supporto dedicato.
      </p>
      <p>
        La somministrazione tanto degli esami orali quanto delle prove scritte
        si avvarrà dell&apos;impiego di Microsoft Teams e del supporto tecnico
        degli incaricati di Ateneo. Le modalità di svolgimento degli esami a
        distanza sono dettagliate nelle Linee guida per docenti e studenti
        disponibili nel tab{" "}
        <NavLink to="linee-guida-esami">Linee guida esami</NavLink>. Potete far
        riferimento alla{" "}
        <a href="https://zeus.unich.it/teledidattica19/doc/Creazione-Team-Appelli-d'esame_v01.pdf">
          guida su come gestire gli appelli d&apos;esame con Teams
        </a>
        .
      </p>
      <p>
        Gli esami di profitto si possono programmare come riunioni
        all&apos;interno del team già creato per le lezioni, eventualmente
        sfruttando la possibilità di creare dei canali all&apos;interno del
        team.
      </p>
      <Alert variant="primary">
        <span className="text-danger font-weight-bold">SUPPORTO ESAMI</span> I
        docenti che ne avessero necessità potranno richiedere supporto
        nell&apos;organizzazione delle prove d&apos;esame scrivendo a
        <a href="mailto:supportoesamionline@unich.it">
          {" "}
          supportoesamionline@unich.it
        </a>
        .
      </Alert>
      { isLoading ? (
        <Loader />
      ) : (
        isSuccess && <MenuPane menuItems={exams2020} filter='exam' />
      ) }
    </>
  );
};

export default Exams2020;
