import { useMutation, queryCache } from "react-query";
import authInstance from "../API/AuthInstance";

const { REACT_APP_API } = process.env;

export default function useDeleteContext() {
  return useMutation(
    async ({ id }: { id: string; category: string }) => {
      const { data } = await authInstance.delete(
        `${REACT_APP_API}/team-channel/${id}`
      );
      return data;
    },
    {
      onSuccess: (data, inputParams) => {
        queryCache.removeQueries(inputParams.category);
      },
    }
  );
}
