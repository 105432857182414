import { useQuery } from "react-query";
import authInstance from "../API/AuthInstance";

const getAdmin = async () => {
  const { data } = await authInstance.get(`/user`);
  return data;
};

export default function useAdmin() {
  return useQuery(["getAdmin"], getAdmin);
}
