import React, { useMemo } from "react";

import { Alert } from "react-bootstrap";
import { Course } from "../../components/innerMenu/types";
import { CourseOfStudy } from "../../interfaces/courseOfStudyType";
import Loader from "../../components/loader/Loader";
import MenuPane from "../../components/menuPane/MenuPane";
import { dashify } from "../../utils";
import useExams from "../../hooks/useExams";
import { useRouteMatch } from "react-router-dom";

const OnlineLessons: React.FunctionComponent = () => {
  const match = useRouteMatch();
  const { data, isSuccess, isLoading } = useExams();

  const onlineLessons = useMemo(() => {
    if (data !== undefined) {
      return data?.map((cos: CourseOfStudy) => {
        const pathOfStudies: Course[] = cos.pathOfStudies.map((path) => {
          return {
            id: path.id,
            label: `${path.description
              .substring(path.description.indexOf("percorso") + 9)
              .toUpperCase()}`,
            courseOfStudyCode: path.courseOfStudyCode,
            link: `${match.path}/${dashify(cos.description)}-${
              cos.code
            }/${dashify(path.description)}`,
            teamsLinks: cos.commonPath
              ? path.lessons
                  .concat(cos.commonPath.lessons)
                  .sort((l1, l2) =>
                    l1.description.localeCompare(l2.description)
                  )
              : path.lessons,
            subMenuItems: [],
          };
        });

        return {
          id: cos.code,
          label: `${cos.code} - ${cos.description.toUpperCase()}`,
          link: `${match.path}/${dashify(cos.description)}-${cos.code}`,
          subMenuItems: pathOfStudies,
          teamsLinks:
            pathOfStudies.length > 1 ? [] : pathOfStudies[0].teamsLinks,
        };
      });
    }
    return [];
  }, [data, match.path]);

  return (
    <>
      <p>I team delle lezioni dei precedenti anni accademici sono consultabili{" "}
        in queste pagine:
        <ul>
          <li>
            <a href="https://zeus.unich.it/teledidattica19/#insegnamenti21">
              team lezioni a.a. 2021/2022
            </a>
          </li>
           <li>
            <a href="https://zeus.unich.it/teledidattica19/#insegnamenti20">
              team lezioni a.a. 2020/2021
            </a>
          </li>
          <li>
            <a href="https://zeus.unich.it/teledidattica19/#insegnamenti19">
              team lezioni a.a. 2019/2020
            </a>
          </li>
        </ul>
      </p>
    </>
  );
};

export default OnlineLessons;
