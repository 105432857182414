import { Alert, Card } from "react-bootstrap";

import FaqProps from "./types";
import { NavLink } from "react-router-dom";
import React from "react";

const Faq: React.FunctionComponent<FaqProps> = () => {
  return (
    <>
      <Card className="px-4 py-4 mb-4">
        <h5>Chi deve creare i team per gli insegnamenti?</h5>
        <div>
          I docenti <span className="font-weight-bold">NON DEVONO</span> creare
          autonomamente i team per i propri insegnamenti. Questi sono già stati
          tutti creati in maniera automatica per tutti i CdS. I docenti che
          vogliano sperimentare il software possono usare l&apos;
          <a href="https://teams.microsoft.com/l/team/19%3ab945fc8550cf4606a368c03eee075806%40thread.tacv2/conversations?groupId=fbf56c0e-5091-4b67-b35f-3a44c554725e&tenantId=41f8b7d0-9a21-415c-9c69-a67984f3d0de">
            insegnamento di test
          </a>
          .
        </div>
      </Card>
      <Card className="px-4 py-4 mb-4">
        <h5> Chi può accedere al team?</h5>
        <div>
          I team che stiamo creando sono pubblici. Questo vuol dire che sono
          accessibili a tutti e soli gli utenti dell&apos;ateneo (chiunque abbia
          una email <code>@unich.it</code> o <code>@studenti.unich.it</code>).
          Abbiamo scelto questa modalità perché è più facile per gli studenti
          accedere, soprattutto in questa fase inziale un po&apos; frenetica.
          L&apos;opzione è modificabile cliccando sull&apos;icona con i tre
          puntini accanto al nome del team e selezionando Modifica team. Da qui
          si può impostare il team come privato. In un team privato il docente
          deve autorizzare singolarmente gli studenti che vogliono entrare a
          farne parte.
        </div>
      </Card>
      <Card className="px-4 py-4 mb-4">
        <h5> Come posso sapere chi è iscritto al team?</h5>
        <div>
          Basta cliccare sull&apos;icona con i tre puntini accanto al nome del
          team e selezionare Gestisci team. Comparità l&apos;elenco dei
          partecipanti al team diviso in due gruppi: &quot;proprietari&quot; e
          &quot;membri e ospiti&quot;. Tra i proprietari, a parte il docente del
          corso, sono di solito inseriti vari account Biblos e talvolta anche
          l&apos;account di un docente del team tecnico che si occupa della
          teledidattica (Gianluca Amato o Francesca Scozzari). Si prega di non
          rimuovere questi account dal team, a meno di essere assolutamente
          certi di non aver bisogno di assistenza. La sezione relativa a membri
          e ospiti contiene l&apos;elenco degli studenti iscritti al team.
          Questa sezione è poco affidabile: talvolta indica l&apos;assenza di
          membri nel team anche quando in realtà ci sono.
        </div>
      </Card>
      <Card className="px-4 py-4 mb-4">
        <h5>Chi può inserire messaggi e riunioni nel team?</h5>
        <div>
          Quando un team viene creato, per default tutti gli utenti possono
          aggiungere messaggi (post) ed indire riunioni. L&apos;opzione è
          modificabile cliccando sull&apos;icona con i tre puntini accanto alla
          scritta Generale, a sinistra sotto il nome del team, e selezionando
          Gestisci canale. Attenzione che impedire agli studenti di aggiungere
          post impedisce loro anche di inviare messaggi nelle chat delle
          lezioni.
        </div>
      </Card>
      <Card className="px-4 py-4 mb-4">
        <h5>
          Gli studenti possono cancellare e modificare i file che condivido?
        </h5>
        <div>
          Normalmente lo spazio in un team dedicato alla condivisione dei file è
          aperto a tutti, anche agli studenti. Questi possono cancellare e/o
          modificare i file a piacimento. Ciò non vale però per i file
          memorizzati nella cartella Materiale didattico del docente. Si
          consiglia quindi di inserire i propri file in tale cartella.
        </div>
      </Card>
      <Card className="px-4 py-4 mb-4">
        <h5>
          Come si cancella un video che ho registrato per prova (o per errore)?
        </h5>
        <div>
          Le registrazioni dei video non sono in Teams ma vengono inviate
          automaticamente al sito di streaming{" "}
          <a href="https://web.microsoftstream.com/">Streams</a>, dove
          selezionando nel menu Contenuto personale -&gt; Video troverete tutti
          i video. Per cancellare un video cliccare nell&apos;icona con 3
          puntini ... e poi Elimina. (In caso di errore nell&apos;eliminazione,
          il video rimane nel cestino per 30 giorni).
        </div>
      </Card>
      <Card className="px-4 py-4 mb-4">
        <h5>Chi può vedere i video? Come si modifica questa impostazione?</h5>
        <div>
          Quando si registra un video in un Team pubblico (e quasi tutti i Team
          per le lezioni lo sono), anche i video sono pubblici, nel senso che
          possono vederli tutti i docenti e gli studenti dell&apos;ateneo
          registrati su Teams. È possibile modificare questa impostazione
          cambiando il Team da pubblico a privato. In questo modo, tutti i video
          (anche quelli registrati precedentemente a questa modifica) saranno
          visibili solo ai membri del Team (gli studenti). Per impostare un Team
          privato, all&apos;interno del Team in oggetto, cliccando sui tre
          puntini accanto al nome dell&apos;insegnamento, si selezioni Modifica
          team e si cambi il campo Privacy in Privato. La modifica ha effetto
          dopo circa un&apos;ora. Si noti che questa modifica non permette più
          l&apos;accesso libero agli studenti. Ogniqualvolta un nuovo studente
          vorrà accedere al Team, il docente riceverà un notifica con la
          richiesta di accesso, che dovrà autorizzare.
        </div>
      </Card>
      <Card className="px-4 py-4 mb-4">
        <h5> Come si pianifica una lezione? E come si inizia una lezione?</h5>
        <div>
          Consulta i video tutorial nella sezione{" "}
          <NavLink to="/tutorial">Video tutorial</NavLink>.
        </div>
      </Card>
      <Card className="px-4 py-4 mb-4">
        <h5> Non mi appare l&apos;icona con il calendario. Dove la trovo?</h5>
        <div>
          È un problema di licenza che possono risolvere i tecnici
          dell&apos;ateneo. Invia una mail a{" "}
          <a href="mailto:didatticaadistanza@unich.it">
            didatticaadistanza@unich.it
          </a>{" "}
          specificando che non appare l&apos;icona del calendario.
        </div>
      </Card>
      <Card className="px-4 py-4 mb-4">
        <h5>
          {" "}
          Come posso aggiungere un altro docente (borsista, assegnista,
          dottorando, etc etc) che collabora al mio insegnamento?
        </h5>
        <div>
          All&apos;interno del Team nel quale si vuole aggiungere il co-docente,
          cliccando sui tre puntini accanto al nome dell&apos;insegnamento, si
          selezioni Aggiungi membro ricercando il nome del co-docente e premendo
          il pulsante Aggiungi. Si modifichi poi Membro in Proprietario e si
          chiuda con il pulsante Chiudi.
        </div>
      </Card>
      <Card className="px-4 py-4 mb-4">
        <h5>Ho una classe numerosa (più di 250 studenti). Cosa posso fare?</h5>
        <div>
          Le riunioni di Team consentono di avere al massimo 250 partecipanti.
          Per fare lezione a classi più numerose bisogna utilizzare
          un&apos;altra funzionalità di Team, gli eventi live. Consulta i{" "}
          <NavLink to="/tutorial">video tutorial</NavLink>. nella sezione Video
          tutorial per capire come funzionano questi eventi. Normalmente i
          docenti non sono abilitati alla creazione di eventi live, perché
          dobbiamo limitarne l&apos;uso (ce ne possono essere al massimo 15
          attivi contemporaneamente). Se avete bisogno di questa funzione,
          contattate i proff.{" "}
          <a href="mailto:gianluca.amato@unich.it">Gianluca Amato</a> o{" "}
          <a href="mailto:francesca.scozzari@unich.it">Francesca Scozzari</a>{" "}
          che potranno abilitarvi.
        </div>
      </Card>
      <Card className="px-4 py-4 mb-4">
        <h5>Come si procede per le attività di tutorato?</h5>
        <div>
          Gli studenti-tutor possono creare autonomamente un Team. Si consiglia
          di scegliere la tipologia &quot;Altro&quot; e di inserire come privacy
          &quot;Pubblico&quot;, in modo tale che gli studenti fruitori del
          tutorato possano entrare autonomamente nel Team. I Team così creati
          possono poi essere pubblicizzati negli usuali canali informativi del
          CdS (ad esempio con un avviso nel sito web del CdS o del Dipartimento
          o nei canali social) pubblicando il link al Team che ottenete
          cliccando nell&apos;icona con 3 puntini ... accanto al nome del Team e
          selezionando Richiedi collegamento al team.
        </div>
      </Card>
      <Card className="px-4 py-4 mb-4">
        <h5>Posso creare nel mio Team una cartella riservata al docente?</h5>
        <div>
          Il proprietario del Team può creare una cartella riservata alla quale
          gli studenti non possono accedere, neanche in lettura, con la seguente
          procedura:
        </div>
        <ul>
          <li>
            innanzitutto creare una cartella nel tab File con &quot;+
            Nuovo&quot; -&gt; &quot;Cartella&quot;
          </li>
          <li>
            cliccando sui tre puntini accanto al nome della cartella,
            selezionare &quot;Apri in SharePoint&quot;
          </li>
          <li>
            si apre una finestra sul browser, cliccare su &quot;Documenti&quot;
          </li>
          <li>
            accanto al nome della nuova cartella, cliccare sui tre puntini in
            verticale &quot;Mostra azioni&quot; -&gt; &quot;Gestisci
            accesso&quot;
          </li>
          <li>
            nella riga &quot;Visitatori di...&quot; dalla freccia verso il basso
            selezionare &quot;Interrompi condivisione&quot; e poi il pulsante
            &quot;Rimuovi&quot;
          </li>
          <li>
            alla riga &quot;Membri di...&quot; dalla freccia verso il basso
            selezionare &quot;Interrompi condivisione&quot; e poi il pulsante
            &quot;Rimuovi&quot;
          </li>
        </ul>
      </Card>
      <Alert variant="primary">
        <strong className="text-danger">FAQ tecniche</strong>: le informazioni
        che seguono riguardano aspetti più tecnici di Teams. Per ulteriori
        informazioni contattare Francesca Scozzari o Gianluca Amato.
      </Alert>
      <Card className="px-4 py-4 mb-4">
        <h5>
          Chi può partecipare ad un Team, una riunione oppure un evento live?
        </h5>
        <div>In Teams ci sono 3 tipi di utenti:</div>
        <ul>
          <li>
            <span className="font-weight-bold">Standard</span>: si tratta di
            normali utenti con un account @unich.it oppure @studenti.unich.it.
            Questi possono partecipare a tutti i Team, a tutte le riunioni e a
            tutti gli eventi live, in qualsiasi ruolo (purché invitati o
            autorizzati).
          </li>
          <li>
            <span className="font-weight-bold">Ospite</span>: si tratta di
            persone che non hanno né un account @unich.it né @studenti.unich.it
            e partecipano utilizzando un proprio account personale (ad esempio:
            gmail o altro). Come gli utenti Standard, possono essere membri di
            un Team, partecipare alle riunioni e a eventi live in qualsiasi
            ruolo. Non possono però creare Teams né eventi.
          </li>
          <li>
            <span className="font-weight-bold">Anonimo</span>: si tratta di un
            utente che si connette temporaneamente ad una riunione oppure a un
            evento live (e poi scompare). Non occorre nessun account, ed
            accedono semplicemente cliccando su un link a loro inviato o
            pubblicato. Negli eventi live possono solo ascoltare. Nelle riunioni
            possono anche presentare (con slides o webcam).
          </li>
        </ul>
        <div>Come si creano questi utenti?</div>
        <ul>
          <li>
            <span className="font-weight-bold">Standard</span>: con
            l&apos;usuale procedura di creazione account (è necessario avere già
            un account @unich.it oppure @studenti.unich.it).
          </li>
          <li>
            <span className="font-weight-bold">Ospite</span>: gli ospiti devono
            ricevere un invito via email. L&apos;invito può essere creato solo
            dagli amministratori di Teams o persone appositamente delegate.
          </li>
          <li>
            <span className="font-weight-bold">Anonimo</span>: non c&apos;è
            nessun account da creare, si partecipa semplicemente cliccando sul
            link dell&apos;evento.
          </li>
        </ul>
      </Card>
      <Card className="px-4 py-4 mb-4">
        <h5>Cosa si può fare durante una riunione?</h5>
        <div>
          La capacità di intervenire in una riunione NON dipende dal tipo di
          utente (vedi FAQ precedente) ma solo dal suo ruolo nella riunione. I
          ruoli sono:
        </div>
        <ul>
          <li>
            <span className="font-weight-bold">Organizzatore</span>: la persona
            che attiva la riunione; può intervenire in ogni modo (con slides,
            webcam, chat, ...)
          </li>
          <li>
            <span className="font-weight-bold">Relatore</span>: può intervenire
            nella riunione in ogni modo (con slides, webcam, chat, ...)
          </li>
          <li>
            <span className="font-weight-bold">Partecipante</span>: può fare le
            stesse cose del relatore tranne condividere materiale (slides,
            applicazioni, Desktop, ...) né avviare o terminare la registrazione.
            In particolare, può intervenire con la propria webcam e scrivere
            nella chat.
          </li>
        </ul>
        <div>
          Quando si entra in una riunione, si ha automaticamente il ruolo di
          relatore. Tutti i relatori possono cambiare il ruolo di un altro
          utente (da partecipante a relatore e viceversa). Il ruolo
          dell&apos;organizzatore non può essere cambiato.
        </div>
        <div>
          Quando si pianifica una riunione mettendo l&apos;Opzione riunione
          &quot;Chi può essere relatore? Solo io&quot; tutte le persone, tranne
          l&apos;organizzatore, entrano con il ruolo di partecipante (invece di
          quello automatico di relatore).
        </div>
      </Card>
    </>
  );
};

export default Faq;
