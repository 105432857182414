import React, { useState } from "react";
import DashboardProps from "./types";
import AdminList from "../../components/adminsList/AdminsList";
import ToastBar from "../../components/ToastBar/index";

const Dashboard: React.FunctionComponent<DashboardProps> = () => {
  //TODO update title
  const [showModal, setShowModal] = useState<boolean>(false);
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>("");

  const renderToast = (result: boolean, type: string) => {
    setShowModal(true);
    setSuccessModal(result);
    setModalType(type);
  };

  return (
    <>
      <h1>AMMINISTRATORI</h1>
      <ToastBar
        result={successModal}
        setShowModal={setShowModal}
        showModal={showModal}
        type={modalType}
      />
      <AdminList
        showModal={showModal}
        successModal={successModal}
        renderToast={renderToast}
      />
    </>
  );
};

export default Dashboard;
