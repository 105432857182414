import React from "react";
import { Form } from "react-bootstrap";
import ContextFormProps from "./types";

const ContextForm: React.FunctionComponent<ContextFormProps> = ({
  handleSelect,
}) => {
  return (
    <>
      <Form className="mb-4">
        <Form.Group controlId="contextForm.Lg">
          <select
            className="form-control form-control-lg"
            onChange={handleSelect}
          >
            <option value="cla">CLA - Centro Linguistico di Ateneo - 2020/2021</option>
            <option value="cla21">CLA - Centro Linguistico di Ateneo - 2021/2022</option>
            <option value="master">Master</option>
            <option value="organi-collegiali">Organi Collegiali</option>
            <option value="specializzazione">Specializzazione</option>
            <option value="tesi-dottorato">Tesi Dottorato</option>
          </select>
        </Form.Group>
      </Form>
    </>
  );
};

export default ContextForm;
